import styled from '@emotion/styled'
import { ProblemDomain } from '@mathflat/domain/@entities'

import { colors, textEllipsis, typo, whiteAreaCss } from '~/@common/styles'
import { mediaQuery } from '~/@common/styles/mediaQuery'
import { Tooltip2Module } from '~/@common/ui/Tooltip2'

const S = {
  ProblemScoringCardWrapper: styled.div`
    width: 384px;
    height: 100%;
    min-height: 141px;

    ${mediaQuery.underTablet} {
      width: 100%;
      padding: 0 16px;
    }
  `,
  ProblemScoringCardInner: styled.div`
    width: 100%;
    height: 100%;

    ${whiteAreaCss};

    display: flex;
    flex-direction: column;

    ${typo.body01};

    > * + * {
      border-top: 1px solid ${colors.gray.$300};
    }

    .cursor-pointer {
      cursor: pointer;
    }
  `,
  ChoiceAnswer: styled.span`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 16px;
    color: ${colors.black};

    ${typo.caption01};
    position: relative;

    &:after {
      position: absolute;
      content: '';
      width: 16px;
      height: 16px;

      border-radius: var(--Radius-Circle);
      border: 1px solid ${colors.black};
    }
    & + &:before {
      position: absolute;
      content: ',';
      left: -25%;
      bottom: -2px;
    }
    & + & {
      margin-left: 4px;
    }
  `,
  ProblemScoringHeader: styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    flex: 1 0 70px;

    .problem-index {
      flex: 1 0 64px;
      height: 100%;
      min-height: 70px; // 사파리에서 height 늘어나지 않아서 추가함
      border-top-left-radius: var(--Radius-300);

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      color: ${colors.gray.$800};
      ${typo.body01};
      font-weight: bold;
      background-color: ${colors.gray.$100};

      &.${ProblemDomain.SCORING_RESULT.정답} {
        background-color: ${colors.blue.$100};
        color: ${colors.blue.$300};
      }
      &.${ProblemDomain.SCORING_RESULT.오답} {
        background-color: ${colors.red.$100};
        color: ${colors.red.$300};
      }
      &.${ProblemDomain.SCORING_RESULT.모름} {
        background-color: #fff2e2;
        color: ${colors.yellow};
      }

      .problem-number {
        ${typo.body02};
        text-align: center;
        ${textEllipsis(2)};
        word-break: break-word;
        padding: 0 13px;
      }
    }

    .problem-image {
      max-width: 100%;
      max-height: 100%;
    }
    /* .explanation-toggle {
      padding: 6px 10px;
      border-radius: var(--Radius-100);
      background-color: ${colors.gray.$200};
      white-space: nowrap;
      ${typo.caption01};
      font-weight: bold;
      &.on {
        background-color: ${colors.blue.$110};
        color: ${colors.blue.$500};
      }
    } */
  `,
  AnswerArea: styled.div`
    flex: 1 1 calc(100% - 64px);
    ${textEllipsis(1)}
    display: flex;
    align-items: center;
    gap: 8px;
    height: 100%;
    padding: 0 20px;
    text-align: left;

    color: ${colors.gray.$700};
    font-weight: bold;
    ${typo.body02};

    .katex {
      ${typo.body01};
      color: ${colors.gray.$900};
    }
    .katex .base {
      display: contents;
    }
    .katex .mfrac {
      font-size: 24px;
    }

    .mord {
      display: contents;
      white-space: normal;
      word-break: break-all;
    }

    .answer-text {
      flex-shrink: 0;
    }

    .answer-image-container {
      width: 100%;
      height: 48px;

      display: flex;
      align-items: center;

      overflow: hidden;
      cursor: pointer;

      .answer-image {
        max-width: 100%;
        max-height: 100%;
      }
    }

    [data-component=${`${Tooltip2Module.kind}`}] {
      .answer-image-container {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      .katex {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  `,
  UnavailableAnswer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    width: 100%;
    ${typo.body02};
    color: ${colors.gray.$500};
  `,
  ProblemSolvingVideoButton: styled.button`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 82px;
    padding: 20px 0px;
    ${typo.body02};
    color: ${colors.gray.$600};
  `,
  AnswerBadge: styled.span`
    ${typo.caption01};
    font-weight: bold;
  `,
  ProblemScoringContent: styled.div`
    /* flex: 1 0 308px; */
    height: 100%;

    padding: 20px;
    overflow: hidden;
    .problem-content-container {
      width: 100%;
      height: 100%;
      overflow: auto;
    }
    .problem-image {
      max-width: 100%;
    }

    ${mediaQuery.underTablet} {
      height: auto;
    }
  `,
  ProblemScoringFooter: styled.div`
    flex: 0 0 70px;
    background-color: white;
    border-bottom-left-radius: var(--Radius-300);
    border-bottom-right-radius: var(--Radius-300);
  `,
  SolutionModalContent: styled.div`
    display: flex;
    justify-content: center;
    width: 600px;
    height: 470px;
    padding: 20px 0;
    overflow-x: hidden;
    overflow-y: auto;
    img {
      width: 450px;
      object-fit: contain;
    }
  `,
  MobileModalContainer: styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 288px;
    height: 260px;
    overflow: hidden;
    padding-top: 30px;

    ${typo.body01};
    color: ${colors.gray.$900};

    > span {
      max-width: 100%;
    }
    .katex .base {
      width: max-content;
      max-width: 100%;
    }

    .mord {
      white-space: normal;
      word-break: break-all;
    }

    .answer-image {
      width: 100%;
    }
  `,
}
export default S
