// 스키마 작성하기 힘들어서 일단 타입으로 작성하는 파일.
// 추후 스키마 작성할 때 참고

import type { SchoolType } from '~/@entities/SchoolSystem/schema.ts'
import type { Entity as StudentWorkbbookEntity } from '~/@entities/StudentWorkbook/dto'

import { SCHOOL_TYPE_KO_ABBR, SCHOOL_TYPE_KO_FULL } from '../SchoolSystem/constants'

export class CurriculumInfo {
  id: number
  trieKey: Entity.Curriculum['key']
  name: string
  schoolType: SchoolType
  revision: Entity.Curriculum['revisionName']
  orderingNumber: number
  constructor(
    params: Omit<CurriculumInfo, 'korSchoolGradeSemester' | 'curriculumMetaSchoolGradeSemester'> & {
      schoolType: SchoolType
      revision: Entity.Curriculum['revisionName']
      orderingNumber: number
    },
  ) {
    this.id = params.id
    this.trieKey = params.trieKey
    this.name = params.name
    this.schoolType = params.schoolType
    this.revision = params.revision
    this.orderingNumber = params.orderingNumber
  }

  // 초1-1, 중1-1, 고등수학(상)
  get korSchoolGradeSemester() {
    const korSchoolGradeSemester =
      `${this.schoolType !== 'HIGH' ? SCHOOL_TYPE_KO_ABBR[this.schoolType] : ''}` +
      this.name.replace(/\s/g, '')

    const revision = this.revision !== 'CURRICULUM_15' ? `(${this.revision.split('_')[1]}개정)` : ''

    return korSchoolGradeSemester + revision
  }

  // 초등학교-1학년-1학기, 고등학교-고등수학(상)
  get curriculumMetaSchoolGradeSemester() {
    const school = SCHOOL_TYPE_KO_FULL[this.schoolType]
    const grade =
      this.schoolType !== 'HIGH' ? `-${this.name.replace(/\s/g, '').split('-')[0]}학년` : ''
    const semester =
      this.schoolType !== 'HIGH'
        ? `-${this.name.replace(/\s/g, '').split('-')[1]}학기`
        : `${this.name.replace(/\s/g, '')}`

    return school + grade + semester
  }
}

export namespace Entity {
  export class Curriculum {
    key: string
    type: 'MIDDLE_CHAPTER' | 'BIG_CHAPTER'
    id: number
    name: string
    url: null | string
    hidden: boolean
    revisionId: StudentWorkbbookEntity.Revision['id']
    revisionName: string //StudentWorkbbookEntity.Revision[name] 있어야하나?
    schoolId: number
    schoolName: string
    gradeId: number
    gradeName: string
    semesterId: number
    semesterName: string
    subjectId: number | null
    subjectName: string | null
    bigChapterId: number
    bigChapterName: string
    middleChapterId: number
    middleChapterName: string
    littleChapterId: number | null
    littleChapterName: string | null

    constructor(params: Curriculum) {
      this.key = params.key
      this.type = params.type
      this.id = params.id
      this.name = params.name
      this.url = params.url
      this.hidden = params.hidden
      this.revisionId = params.revisionId
      this.revisionName = params.revisionName
      this.schoolId = params.schoolId
      this.schoolName = params.schoolName
      this.gradeId = params.gradeId
      this.gradeName = params.gradeName
      this.semesterId = params.semesterId
      this.semesterName = params.semesterName
      this.subjectId = params.subjectId
      this.subjectName = params.subjectName
      this.bigChapterId = params.bigChapterId
      this.bigChapterName = params.bigChapterName
      this.middleChapterId = params.middleChapterId
      this.middleChapterName = params.middleChapterName
      this.littleChapterId = params.littleChapterId
      this.littleChapterName = params.littleChapterName
    }
  }

  export class CurriculumTree {
    id: Curriculum['id']
    trieKey: string
    type: SchoolType
    revision: string
    data: CurriculumInfo[]

    constructor(params: CurriculumTree) {
      this.id = params.id
      this.trieKey = params.trieKey
      this.type = params.type
      this.revision = params.revision

      if (params.data[0] instanceof CurriculumInfo) {
        this.data = params.data
      } else {
        this.data = params.data.map(
          (d) =>
            new CurriculumInfo({
              ...d,
              schoolType: params.type,
            }),
        )
      }
    }

    static toShortLabel(labelOrTree: string | CurriculumInfo) {
      const label = typeof labelOrTree === 'string' ? labelOrTree : labelOrTree.name

      if (label)
        switch (labelOrTree) {
          case '고등수학(상)':
            return '수학(상)'
          case '고등수학(하)':
            return '수학(하)'
          case '확률과통계':
          case '확률과 통계':
            return '확통'
          default:
            return label
        }
    }
  }
  type CurriculumKey = string
  export class CurriculumTreeColl {
    private _curriculumTreeMap = {
      ELEMENTARY: new Map<CurriculumKey, CurriculumInfo>(),
      MIDDLE: new Map<CurriculumKey, CurriculumInfo>(),
      HIGH: new Map<CurriculumKey, CurriculumInfo>(),
    }

    /**
     * 커리큘럼키로 커리큘럼 가져오기
     * @example
     * "1.2.7.41" => _SchoolCurriculumTree
     */
    private _flatCurriculumMap = new Map<CurriculumKey, CurriculumInfo>()

    /**
     * 한글 이름으로 커리큘럼 가져오기
     * @example
     *  '중2-2' => _SchoolCurriculumTree
     *  '고등수학(하)' => _SchoolCurriculumTree
     */
    private _koCurriculumMap = new Map<string, CurriculumInfo>()

    // 상위(맨 끝자리를 자른) curriculumKey를 반환
    getUpperCurriculumKey = (curriculumKey: string) => {
      return curriculumKey.split('.').slice(0, -1).join('.')
    }

    get curriculumTreeMap() {
      return this._curriculumTreeMap
    }

    // 공통 학급-학년-학기 커리큘럼키로 교과서의 커리큘럼키 가져오기
    getCurriculumKeyForSchoolbook = (curriculumKey: string) => {
      // 교과서의 경우 특정 학년에서 curriculumKey를 그대로 내려보내면 안되기에 mapping하는 로직
      // 특정 학년의 교과서는 2학기를 더 이상 지원하지 않기 때문. (1개의 교과서를 1년동안 사용)
      const curriculumInfo = this.getCurriculumInfoByCurriculumKey(curriculumKey)

      if (!curriculumInfo) return curriculumKey

      const { schoolType, korSchoolGradeSemester } = curriculumInfo

      // 중학교 교과서는 학기 구분이 없기 때문에 학기 curriculumKey가 아닌 학년 curriculumKey를 보내야 함
      if (schoolType === 'MIDDLE') {
        const curriculumKeyBySemester = curriculumKey
        const curriculumKeyByGrade = this.getUpperCurriculumKey(curriculumKeyBySemester)

        return curriculumKeyByGrade
      }

      // 고등수학(하)의 경우 curriculumKey를 고등수학(상)의 curriculumKey를 보내야 함
      if (korSchoolGradeSemester === '고등수학(하)') {
        return this.getCurriculumInfoByKoFullName('고등수학(상)')?.trieKey ?? curriculumKey
      }

      return curriculumKey
    }

    constructor(curriculumTrees: CurriculumTree[]) {
      for (const curriculumTree of curriculumTrees) {
        for (const curriculumTreeElement of curriculumTree.data) {
          const schoolCurriculumTree = new CurriculumInfo(
            Object.assign(
              { schoolType: curriculumTree.type, revision: curriculumTree.revision },
              curriculumTreeElement,
            ),
          )
          const hasCurriculumMapBySchool = this._curriculumTreeMap[curriculumTree.type]
          if (hasCurriculumMapBySchool) {
            this._curriculumTreeMap[curriculumTree.type].set(
              schoolCurriculumTree.trieKey,
              schoolCurriculumTree,
            )
          }
          this._flatCurriculumMap.set(schoolCurriculumTree.trieKey, schoolCurriculumTree)
          this._koCurriculumMap.set(
            schoolCurriculumTree.korSchoolGradeSemester,
            schoolCurriculumTree,
          )
        }
      }
    }

    getCurriculumInfoByKoFullName(ko: string) {
      return this._koCurriculumMap.get(ko)
    }

    get toElementaryArr() {
      return this._curriculumTreeMap['ELEMENTARY'].size > 0
        ? [...this._curriculumTreeMap['ELEMENTARY'].values()]
        : []
    }
    get toMiddleArr() {
      return this._curriculumTreeMap['MIDDLE'].size > 0
        ? [...this._curriculumTreeMap['MIDDLE'].values()]
        : []
    }
    get toHighArr() {
      return this._curriculumTreeMap['HIGH'].size > 0
        ? [...this._curriculumTreeMap['HIGH'].values()]
        : []
    }
    get toArr() {
      return ([] as CurriculumInfo[])
        .concat(this.toElementaryArr, this.toMiddleArr, this.toHighArr)
        .sort((a, b) => a.orderingNumber - b.orderingNumber)
    }
    get toArrWithoutOldCurriculum() {
      return this.toArr.filter(({ name }) => !name.includes('(구)'))
    }

    getCurriculumInfoByCurriculumKey = (trieKey: CurriculumTree['trieKey']) => {
      return this._flatCurriculumMap.get(trieKey)
    }

    static makeCurriculumKeyForGradeSemester = (trieKey: CurriculumTree['trieKey']) => {
      const splitedStr = trieKey.split('.')

      if (splitedStr.length < 5) return trieKey
      if (splitedStr.length < 7) return splitedStr.slice(0, 4).join('.') // 고등학교 단원 커리큘럼키 대응
      return splitedStr.slice(0, 5).join('.')
    }
  }
}
