import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorkbook/api.ts'
import type { StudentWorkbookScoringParams } from '@mathflat/domain/@entities/StudentWorkbook/StudentWorkbookScoring/dto'
import { Entity as StudentWorkbookScoringEntity } from '@mathflat/domain/@entities/StudentWorkbook/StudentWorkbookScoring/dto'
import { makeAutoObservable, runInAction } from 'mobx'

import { RequestScoring } from '~/@common/api'
import { studentWorkbookApi } from '~/@common/api/studentWorkbookApi'
import type { studentWorkbookDetailPathParams } from '~/@common/constants/route'
import { toastService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import { StudentWorkbookDetailDTO } from '~/@entities/StudentWorkbook/StudentWorkbook.dto'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import {
  ProblemScoringViewOption,
  type ProblemScoringViewOptionParams,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

export class StudentWorkbookDetailService<T extends WorkbookDomain.Type = WorkbookDomain.Type> {
  workbookDetail: StudentWorkbookDetailDTO<T> | null = null

  currentPageNumber?: number

  problemScoringViewOption?: ProblemScoringViewOption<'NOT_학습모듈'>

  constructor(pageNumber?: string) {
    makeAutoObservable(this)
    this.currentPageNumber = pageNumber ? parseInt(pageNumber) : undefined
  }

  get currentPage() {
    if (this.currentPageNumber) {
      return this.workbookDetail?.getPage(this.currentPageNumber)
    }
  }

  get currentPageStatus() {
    return this.currentPage?.status
  }

  get currentPageProblemScoringList() {
    return this.currentPage?.problemScoringColl?.toArr.filter((problemScoring) => {
      if (
        this.currentPage?.status === 'INCOMPLETE' &&
        this.problemScoringViewOption?.안푼문제만보기
      ) {
        return problemScoring.localUserInput.isNotLocallyScored
      }
      if (
        this.currentPage?.status !== 'INCOMPLETE' &&
        this.problemScoringViewOption?.오답_모르는문제만보기
      ) {
        return problemScoring.채점결과 === 'WRONG' || problemScoring.채점결과 === 'UNKNOWN'
      }
      return true
    })
  }

  setCurrentPageNumber(pageNumber: string) {
    this.currentPageNumber = parseInt(pageNumber)
  }

  setproblemScoringViewOption = (
    problemScoringViewOption: ProblemScoringViewOptionParams<'NOT_학습모듈'>,
  ) => {
    this.problemScoringViewOption = new ProblemScoringViewOption(problemScoringViewOption)
  }

  fetchSetWorkbookDetail = async (
    studentId: StudentDomain.Id,
    params: Omit<typeof studentWorkbookDetailPathParams, 'pageNumber'>,
  ) => {
    const data = await studentWorkbookApi.getStudentWorkbookDetail(studentId, params)

    this.workbookDetail = new StudentWorkbookDetailDTO<T>(
      data as MathflatApi.Response.StudentWorkbookDetail<T>,
      {
        observable: true,
      },
    )
  }

  fetchSetProblemScoringOfCurrentPage = async (
    params: {
      studentId: string
      pageNumber: number
    } & Omit<typeof studentWorkbookDetailPathParams, 'pageNumber'>,
  ) => {
    const { pageNumber, studentId, studentWorkbookId, revisionId } = params
    const currentPage = this.workbookDetail?.getPage(pageNumber)

    if (currentPage) {
      const progressId = currentPage.progressId
      try {
        const data = await studentWorkbookApi.getOnePageInStudentWorkbookDetail(studentId, {
          studentWorkbookId: parseInt(studentWorkbookId),
          revisionId: parseInt(revisionId),
          progressId,
        })

        const problemScorings = data.content.map((workbookProblem) => {
          return new ProblemScoring<'WORKBOOK'>(
            {
              scoring: new StudentWorkbookScoringEntity.StudentWorkbookScoring<T>({
                scoring: workbookProblem.scoring,
                workbook: {
                  schoolType: this.workbookDetail?.workbook.schoolType,
                  grade: this.workbookDetail?.workbook.grade,
                  semester: this.workbookDetail?.workbook.semester,
                  revision: this.workbookDetail?.workbook.revision,
                  autoScorable: !!this.workbookDetail?.workbook.autoScored,
                  label: workbookProblem.number,
                },
                problem: {
                  ...workbookProblem,
                  ...workbookProblem.problem,
                  ...workbookProblem.xistory?.problem,
                } as StudentWorkbookScoringParams<T>['problem'],
              }),
            },
            workbookProblem.scoring.workbookProblemId,
          )
        })

        runInAction(() => {
          currentPage.problemScoringColl = new ProblemScoringColl<'WORKBOOK'>(problemScorings)
        })

        if (commonRepo.studentAppSetting) {
          this.setproblemScoringViewOption({
            content: {
              status: currentPage.status,
              autoScored: this.workbookDetail!.workbook.autoScored,
              kind: 'WORKBOOK',
              type: this.workbookDetail!.workbook.type,
            },
            studentAppSetting: commonRepo.studentAppSetting,
            문제같이보기: this.problemScoringViewOption?.문제같이보기,
            오답_모르는문제만보기: this.problemScoringViewOption?.오답_모르는문제만보기,
            안푼문제만보기: this.problemScoringViewOption?.안푼문제만보기,
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
  }

  // onAllPageCheckCorrect() {
  //   if (!this.workbookDetail?.workbook.autoScored) {
  //     this.workbookDetail?.allPages.forEach(({ problemScoringColl }) => {
  //       problemScoringColl?.onAllCheckCorrect()
  //     })
  //   }
  // }

  // onAllPageClear() {
  //   this.workbookDetail?.allPages.forEach(({ problemScoringColl }) => {
  //     problemScoringColl?.onAllClear()
  //   })
  // }
  onSubmit = async (params: Omit<typeof studentWorkbookDetailPathParams, 'pageNumber'>) => {
    try {
      const payload = this.currentPage?.problemScoringColl?.toScoredArr

      if (payload && payload.length > 0) {
        // 자동채점이라면
        if (payload[0].localUserInput instanceof RequestScoring.자동채점) {
          await studentWorkbookApi.제출하기.자동채점(
            this.currentPage.progressId,
            payload.map(({ localUserInput }) =>
              RequestScoring.자동채점.toRequestParams<'WORKBOOK'>(
                localUserInput as RequestScoring.자동채점<'WORKBOOK'>,
              ),
            ),
          )
        } else {
          // 일반채점이라면
          await studentWorkbookApi.제출하기.일반채점(
            payload.map(({ localUserInput }) =>
              RequestScoring.일반채점.toRequestParams<'WORKBOOK'>(
                localUserInput as RequestScoring.일반채점<'WORKBOOK'>,
              ),
            ),
          )
        }

        payload?.forEach((item) => {
          localStorageService.removeScoringData({ scoringId: item.id })
        })

        if (this.currentPageNumber && commonRepo.studentId) {
          await this.fetchSetWorkbookDetail(commonRepo.studentId, params)
          this.fetchSetProblemScoringOfCurrentPage({
            ...params,
            studentId: commonRepo.studentId,
            pageNumber: this.currentPageNumber,
          })
        }
      }
    } catch (err: any) {
      if (err?.response?.data?.code === 'STUDENT_WORKBOOK_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    }
  }
  onSubmitVideoAssist = async ({
    workbookProblemId,
    assistType,
  }: {
    workbookProblemId: Parameters<typeof studentWorkbookApi.문풀동시청기록>[0]['workbookProblemId']
    assistType: Parameters<typeof studentWorkbookApi.문풀동시청기록>[1]
  }) => {
    try {
      if (this.workbookDetail?.workbook.type !== 'CUSTOM_SIGNATURE') return
      if (!this.currentPage?.progressId) return
      await studentWorkbookApi.문풀동시청기록(
        {
          progressId: this.currentPage.progressId,
          workbookProblemId,
        },
        assistType,
      )
    } catch (err: unknown) {
      console.error(err)
    }
  }
}
