import { WorksheetDomain } from '@mathflat/domain/@entities'
import { Entity as WorksheetEntity } from '@mathflat/domain/@entities/(Content)/Worksheet/dto'
import { SCHOOL_TYPE } from '@mathflat/domain/@entities/SchoolSystem/constants'
import type { MathflatApi } from '@mathflat/domain/@entities/StudentWorksheet/api'
import { Entity as StudentWorksheetScoringEntity } from '@mathflat/domain/@entities/StudentWorksheet/StudentWorksheetScoring/dto'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'

import { RequestScoring, studentWorksheetApi } from '~/@common/api'
import { toastService } from '~/@common/services'
import { commonRepo } from '~/@common/services/repo.service'
import { localStorageService } from '~/@common/services/storage.service'
import {
  ProblemScoring,
  ProblemScoringColl,
} from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoring.trait'
import { ProblemScoringViewOption } from '~/@pages/@common/(ProblemScoring)/@trait/ProblemScoringViewOption.trait'

class WorksheetValidation {
  studentWorksheetId: MathflatApi.Response.StudentWorksheetValidation['studentWorksheetId'] = null
  worksheetState: MathflatApi.Response.StudentWorksheetValidation['worksheetState']
  constructor(validation: MathflatApi.Response.StudentWorksheetValidation) {
    this.studentWorksheetId = validation.studentWorksheetId
    this.worksheetState = validation.worksheetState
  }
}

export class WorksheetScoringService {
  private _validation: WorksheetValidation | null = null

  problemScoringColl?: ProblemScoringColl<'WORKSHEET'>
  problemScoringViewOption?: ProblemScoringViewOption<'NOT_학습모듈'>
  worksheet?: WorksheetEntity.CustomWorksheet

  constructor() {
    makeObservable<this, '_validation'>(this, {
      _validation: observable,
      validation: computed,
      checkWorksheetValidation: action.bound,

      problemScoringColl: observable,
      problemScoringViewOption: observable,
      clearAllAnswer: action.bound,
      scoringProblemsForSubmit: computed,
      assignWorksheetToStudent: action.bound,
      submitScoringStudentWorksheetProblems: action.bound,
      loadWorksheetWithProblems: action.bound,
    })
  }

  get scoringProblemsForSubmit() {
    return this.problemScoringColl?.toArr.filter(
      (scoring) => !scoring.localUserInput.isNotLocallyScored,
    )
  }

  get validation() {
    return this._validation
  }

  clearAllAnswer() {
    this.problemScoringColl?.onAllClear()
  }

  assignWorksheetToStudent(worksheetId: string) {
    return studentWorksheetApi.postToAssignWorksheetToStudent(worksheetId)
  }

  async submitScoringStudentWorksheetProblems(worksheetId: string, studentWorksheetId: string) {
    try {
      const payload = this.problemScoringColl?.toScoredArr

      if (payload && payload.length > 0) {
        // 자동채점이라면
        if (payload[0].localUserInput instanceof RequestScoring.자동채점) {
          await studentWorksheetApi.patchAutoScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.자동채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.자동채점,
              ),
            ),
          )
        } else {
          // 일반채점이라면
          await studentWorksheetApi.patchScoringAssignedStudentWorksheet(
            studentWorksheetId,
            payload.map(({ localUserInput }) =>
              RequestScoring.일반채점.toRequestParams<'WORKSHEET', '일반'>(
                localUserInput as RequestScoring.일반채점,
              ),
            ),
          )
        }
      }

      payload?.forEach((item) => {
        localStorageService.removeScoringData({ scoringId: item.id })
      })
    } catch (error: any) {
      if (error?.response?.data?.code === 'STUDENT_WORKSHEET_PROBLEM_DUPLICATE_SUBMIT') {
        toastService.error('이미 제출한 답안은 수정할 수 없습니다.', { allowMultipleToast: true })
      }
    }

    // await this.loadStudentWorksheetProblems(studentWorksheetId)
  }

  async loadWorksheetWithProblems(worksheetId: string) {
    const { data } = await studentWorksheetApi.getWorksheetWithProblems(worksheetId)

    runInAction(() => {
      this.problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(
        data.worksheetProblems.map(
          ({ problem, worksheetProblemId, result, userAnswer }, index) =>
            new ProblemScoring<'WORKSHEET'>(
              {
                scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
                  worksheet: {
                    autoScorable: data.autoScorable,
                    label: `${index + 1}번`,
                  },
                  problem,
                  scoring: { result, userAnswer, worksheetProblemId },
                }),
              },
              worksheetProblemId,
            ),
        ),
      )

      if (commonRepo.studentAppSetting) {
        this.problemScoringViewOption = new ProblemScoringViewOption({
          content: {
            status: data.status,
            autoScored: data.autoScorable,
            kind: 'WORKSHEET',
            worksheetId: Number(worksheetId),
          },
          studentAppSetting: commonRepo.studentAppSetting,
        })
      }

      // fetch해서 넣으면 좋겠지만 낭비. default 가짜 데이터로 채우기
      this.worksheet = new WorksheetEntity.CustomWorksheet({
        id: parseInt(worksheetId),
        type: WorksheetDomain.TYPE.CUSTOM,
        school: SCHOOL_TYPE.초등학교,
        grade: '1',
        title: '',
        titleTag: '',
        chapter: '',
        tag: 'BASIC',
        problemCount: data.worksheetProblems.length,
        level: 1,
        autoScorable: data.autoScorable,
        accessModifierToStudent: 'PUBLIC',
      })
    })
  }

  async checkWorksheetValidation(worksheetId: string, dateTime: string) {
    const { data: validation } = await studentWorksheetApi.postWorksheetValidation({
      worksheetId,
      dateTime,
    })

    runInAction(() => {
      this._validation = new WorksheetValidation(validation)
    })
  }

  // async loadStudentWorksheetProblems(studentWorksheetId: string) {
  //   const problemScorings = await studentWorksheetApi.getAssignedStudentWorksheetWithProblems(
  //     parseInt(studentWorksheetId),
  //   )

  //   runInAction(() => {
  //     this.problemScoringColl = new ProblemScoringColl<'WORKSHEET'>(
  //       problemScorings.map(
  //         ({ problem, worksheetProblemId, scoring }, index) =>
  //           new ProblemScoring<'WORKSHEET'>(
  //             {
  //               scoring: new StudentWorksheetScoringEntity.StudentWorksheetScoring({
  //                 worksheet: {
  //                   autoScorable: this.worksheetWithProblems?.autoScorable ?? false,
  //                   label: `${index + 1}번`,
  //                 },
  //                 problem,
  //                 scoring: { ...scoring, worksheetProblemId },
  //               }),
  //             },
  //             worksheetProblemId,
  //           ),
  //       ),
  //     )
  //   })
  // }
}
