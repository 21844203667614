import { ProblemDomain } from '@mathflat/domain/@entities'
import clsx from 'clsx'
import { observer } from 'mobx-react'
import { useMemo, useState } from 'react'

import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { CustomEventService } from '~/@common/services/event.service'
import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'
import { Icon } from '~/@common/ui/Icon/Icon'
import Katex from '~/@common/ui/Katex/Katex'
import Tooltip2 from '~/@common/ui/Tooltip2/Tooltip2'
import type { StudentWorkbookDetailService } from '~/@pages/student/student-workbook/detail/@service/StudentWorkbookDetail.service'

import type { ProblemScoring } from '../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../@trait/ProblemScoringViewOption.trait'
import { AnswerVideoDrawer } from './_component/AnswerVideoDrawer/AnswerVideoDrawer'
import S from './ProblemScoringCard.style'

export type ProblemScoringHeaderProps = {
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isSubmittedAnswerAvailable?: boolean
  onSubmitVideoAssist?: StudentWorkbookDetailService['onSubmitVideoAssist']
}
const ProblemScoringHeader = ({
  problemScoring,
  viewOption,
  isSubmittedAnswerAvailable = true,
  onSubmitVideoAssist,
}: ProblemScoringHeaderProps) => {
  return (
    // TODO: data-tooltip-id={`${problemScoring.id}`} 삭제해도 되는지 다슬쌤께 묻기
    <S.ProblemScoringHeader data-tooltip-id={`answer-${problemScoring.id}`}>
      <div className={clsx('problem-index', problemScoring.isSubmitted && problemScoring.채점결과)}>
        {problemScoring.isSubmitted && (
          <Icon
            name={
              problemScoring.채점결과 === ProblemDomain.SCORING_RESULT.정답
                ? 'icon_answer_correct'
                : problemScoring.채점결과 === ProblemDomain.SCORING_RESULT.오답
                  ? 'icon_answer_wrong'
                  : 'icon_answer_question'
            }
            size={20}
          />
        )}
        <span className="problem-number">{problemScoring.문제번호}</span>
        {!problemScoring.isSubmitted &&
          problemScoring.isAutoScoring &&
          problemScoring.문제정답타입 === 'MULTIPLE_CHOICE' && (
            <S.AnswerBadge>정답 {problemScoring.문제정답.split(',').length}개</S.AnswerBadge>
          )}
      </div>

      <AnswerArea
        problemScoring={problemScoring}
        viewOption={viewOption}
        isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
        onSubmitVideoAssist={onSubmitVideoAssist}
      />
    </S.ProblemScoringHeader>
  )
}

const AnswerArea = observer(
  ({
    problemScoring,
    viewOption,
    isSubmittedAnswerAvailable,
    onSubmitVideoAssist,
  }: ProblemScoringHeaderProps) => {
    const { isMobile } = useStudentAppMediaQuery()

    const isAnswerLocked = useMemo(() => {
      if (!problemScoring.isSubmitted) return false
      if (!viewOption.studentAppSetting) return false
      return !viewOption.studentAppSetting.채점후정답해설공개
    }, [problemScoring.isSubmitted, viewOption.studentAppSetting])

    const showVideoButton = useMemo(() => {
      if (!viewOption.studentAppSetting) return false
      const 일반채점_채점전_문풀동공개 =
        !problemScoring.isAutoScoring &&
        !problemScoring.isSubmitted &&
        viewOption.studentAppSetting.채점전문풀동공개 &&
        viewOption.studentAppSetting.일반채점_채점전정답공개

      const 자동채점_채점전_문풀동공개 =
        problemScoring.isAutoScoring &&
        !problemScoring.isSubmitted &&
        viewOption.studentAppSetting.채점전문풀동공개

      return 일반채점_채점전_문풀동공개 || 자동채점_채점전_문풀동공개
    }, [problemScoring, viewOption.studentAppSetting])

    // 1. MAAT
    if (viewOption.content.type === 'MAAT') {
      return (
        <S.UnavailableAnswer>
          <Icon name="icon_check" size={20} color={colors.gray.$500} />
          정답은 결과지에서 확인 가능합니다.
        </S.UnavailableAnswer>
      )
    }
    // 2. 정답 보여주지 않는 케이스
    if (isAnswerLocked) {
      return (
        <S.UnavailableAnswer>
          <Icon name="icon_lock_line" size={20} color={colors.gray.$500} />
          정답 ・ 해설
          {!viewOption.studentAppSetting?.채점후문풀동공개 && ' ・ 풀이 영상'}이 비공개 상태입니다.
        </S.UnavailableAnswer>
      )
    }

    // 3. 자동채점이면서 채점 전인 경우
    if (!problemScoring.isSubmitted && problemScoring.isAutoScoring) {
      // 3-1. 자기주도학습
      if (!viewOption.studentAppSetting) {
        return (
          <S.UnavailableAnswer>
            <Icon name="icon_check" size={20} color={colors.gray.$500} />
            채점 후 정답 ・ 해설 ・ 풀이 영상이 공개됩니다.
          </S.UnavailableAnswer>
        )
      }

      if (!viewOption.studentAppSetting.채점전문풀동공개 || viewOption.content.type === 'EXAM') {
        return <></>
      }
      // 3-2. 풀이 동영상을 보여주는 경우
      if (showVideoButton) {
        return (
          <S.AnswerArea>
            <ProblemSolvingVideoButton
              problemScoring={problemScoring}
              viewOption={viewOption}
              isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
              onSubmitVideoAssist={onSubmitVideoAssist}
            />
          </S.AnswerArea>
        )
      }
    }

    // 4. 일반채점이면서 채점 전인 경우
    if (!problemScoring.isSubmitted && !problemScoring.isAutoScoring) {
      if (!viewOption.studentAppSetting?.일반채점_채점전정답공개) {
        return (
          <S.UnavailableAnswer>
            <Icon name="icon_lock_line" size={20} color={colors.gray.$500} />
            선생님께 채점을 요청해주세요.
          </S.UnavailableAnswer>
        )
      }
    }

    // 5. 기본 답안 영역 표시
    return (
      <S.AnswerArea
        className="cursor-pointer"
        onClick={(e) => {
          e.stopPropagation()
          if (isMobile) {
            modalService.openModal(
              <S.MobileModalContainer>
                <Answer problemScoring={problemScoring} />
              </S.MobileModalContainer>,
              {
                modalName: '세로모드 - 정답',
                hasCloseButton: true,
              },
            )
          } else {
            CustomEventService.tooltipOn.dispatch(`answer-${problemScoring.id}`, e)
          }
        }}
      >
        <span className="answer-text">답 :</span>
        <Answer problemScoring={problemScoring} />
        <Tooltip2.WhiteArea id={`answer-${problemScoring.id}`}>
          <Answer problemScoring={problemScoring} />
        </Tooltip2.WhiteArea>
        {showVideoButton && (
          <ProblemSolvingVideoButton
            problemScoring={problemScoring}
            viewOption={viewOption}
            isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
            onSubmitVideoAssist={onSubmitVideoAssist}
          />
        )}
      </S.AnswerArea>
    )
  },
)

const ProblemSolvingVideoButton = observer(
  ({
    problemScoring,
    viewOption,
    isSubmittedAnswerAvailable,
    onSubmitVideoAssist,
  }: ProblemScoringHeaderProps) => {
    const [openDrawer, setOpenDrawer] = useState(false)

    return (
      <S.ProblemSolvingVideoButton
        onClick={(e) => {
          e.stopPropagation()
          setOpenDrawer((prev) => !prev)
        }}
      >
        풀이동영상
        <Icon name="icon_chevron_right" color={colors.gray.$500} size={20} />
        <AnswerVideoDrawer
          problemScoring={problemScoring}
          viewOption={viewOption}
          isSubmittedAnswerAvailable={isSubmittedAnswerAvailable}
          openDrawer={openDrawer}
          onSubmitVideoAssist={onSubmitVideoAssist}
        />
      </S.ProblemSolvingVideoButton>
    )
  },
)

export const Answer = observer(
  ({ problemScoring }: { problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'> }) => {
    if (problemScoring.문제정답이미지) {
      return (
        <div className="answer-image-container">
          <img
            src={problemScoring.문제정답이미지}
            // 테스트 긴 이미지
            // src={`https://s3-alpha-sig.figma.com/img/9541/5f0d/6e43ee459bd3be7801f0cdd8c4faf6af?Expires=1699833600&Signature=k036HBoT2vW0tfVXOREep4rXMepzrLLS2ajEfChdZ24evtuW2F3M3Gb2011kx6wb6E0l01C0gtWuQqTinisz9Toc8fDkCIsXaByqnp8XdoEOVuqvgYxZT848fzKGCXGOiW4elQM3c-78F1L8f4Vce14x7NSYjpbZZqgemy5D9UyCdE5g6cnzcQHLQCXGeUMDHxbwWNXaX3zqPOOYsXE8iUJExKWurT4BB7auPrb1M9-HzgH1dGAtN4e~1TiA-ya3FB6LfcFFPc~bxJI7wxrvQymgm5bLV~a2aValBB1w4VyV1erAlrgGwYa7sWUnW1SVIJmjb1zFy~IFWaK5NiV8ig__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4`}
            alt="answer-image"
            className="answer-image"
          />
        </div>
      )
    }

    if (problemScoring.문제정답 !== '.') {
      if (problemScoring.문제정답타입 === 'SHORT_ANSWER') {
        return <Katex>{problemScoring.문제정답}</Katex>
      }

      return (
        <>
          {problemScoring.문제정답.split(',').map((answer) => (
            <S.ChoiceAnswer key={answer} className="choice-answer">
              {answer}
            </S.ChoiceAnswer>
          ))}
        </>
      )
    }
    return null
  },
)
export default ProblemScoringHeader
