import type { WorkbookApi } from '@mathflat/domain/@entities/(Content)/Workbook/api'
import type { WorkbookDomain } from '@mathflat/domain/@entities/(Content)/Workbook/domain'
import type { Pagination } from '@mathflat/shared/@types/mathflatApi'

import type { WorkbookPageData } from '../types'
import maxios from '../utils/maxios'

export const workbookApi = (() => {
  return {
    async getWorkbooks<
      T extends WorkbookDomain.Type = WorkbookDomain.Type,
      U extends 'GRADE' | 'CLASS' | 'STUDENT' = never,
    >(params: WorkbookApi.Request.GetWorkbook<T, U>) {
      const {
        data: { content },
      } = await maxios.get<Pagination<WorkbookApi.Response.GetWorkbook<T>[]>>('/workbook', {
        params,
      })

      return content
    },
    getWorkbookPages: async (workbookId: number) => {
      const { data } = await maxios.get<WorkbookPageData[]>(
        `/workbook/custom-signature/${workbookId}/page`,
      )
      return data
    },
  }
})()
