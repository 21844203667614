import type {
  Entity as CurriculumEntity,
  CurriculumInfo,
} from '@mathflat/domain/@entities/Curriculum/dto'
import { curriculumUtils } from '@mathflat/domain/@entities/Curriculum/util'
import type { MathflatApi } from '@mathflat/domain/@entities/Lecture/api'
import { Entity as LectureEntity } from '@mathflat/domain/@entities/Lecture/dto'
import { SCHOOL_TYPE } from '@mathflat/domain/@entities/SchoolSystem/constants.ts'
import type { SchoolType } from '@mathflat/domain/@entities/SchoolSystem/schema.ts'
import type { StudentDomain } from '@mathflat/domain/@entities/Student/domain.ts'
import { groupBy } from 'lodash'
import { makeAutoObservable, runInAction, toJS } from 'mobx'

import { lectureApi } from '~/@common/api/lectureApi'
import {
  CURRICULUM_KEY_고등학교_대수_22개정,
  CURRICULUM_KEY_중학교_1학년_2학기_22개정,
  CURRICULUM_KEY_중학교_2학년_1학기_22개정,
} from '~/@common/constants'
import { commonRepo } from '~/@common/services/repo.service.ts'

const EXCLUDED_CURRICULUM_KEY = [
  CURRICULUM_KEY_중학교_1학년_2학기_22개정,
  CURRICULUM_KEY_중학교_2학년_1학기_22개정,
  CURRICULUM_KEY_고등학교_대수_22개정,
]

export type LectureGroup = {
  leadingCurriculum: MathflatApi.Response.LectureByTrieKey['curriculum'] // 대단원이 될수도 중단원이 될 수도
  middleChapterLectures: MiddleChapterLecture[]
}

export class LectureService {
  lectureGroups?: LectureGroup[]

  _selectedCurriculumInfo: CurriculumInfo | null = null
  isFetching = false

  constructor() {
    makeAutoObservable(this)
  }

  get selectedCurriculumInfo() {
    if (this._selectedCurriculumInfo) return this._selectedCurriculumInfo
    if (commonRepo.studentCurriculumInfo) {
      return commonRepo.curriculumTreeColl?.toMiddleArr[0]
    }
    return null
  }

  // 커리큘럼 선택
  setCurriculumAndFetchLectureGroups(
    {
      curriculumInfo,
      studentId,
    }: {
      curriculumInfo: CurriculumInfo
      studentId: StudentDomain.Id
    },
    curriculumType: CurriculumEntity.Curriculum['type'] = 'BIG_CHAPTER',
  ) {
    if (studentId) {
      this._selectedCurriculumInfo = toJS(curriculumInfo)
      this.fetchSetLectureGroups({ trieKey: curriculumInfo.trieKey, studentId }, curriculumType)
    }
  }

  // 초중고 선택
  setSelectSchoolType = (schoolType: SchoolType) => {
    if (!this.selectableCurriculumList[schoolType])
      throw Error('selectableDropdownList[schoolType] is nullable')

    if (!commonRepo.studentId) {
      throw Error('studentId is missing')
    }

    const curriculums = this.selectableCurriculumList[schoolType]

    if (!curriculums) throw Error('curriculums is nullable')

    this.setCurriculumAndFetchLectureGroups({
      curriculumInfo: curriculums[0],
      studentId: commonRepo.studentId,
    })
  }

  get selectableCurriculumList() {
    const sortByOrderingNumber = (a: CurriculumInfo, b: CurriculumInfo) =>
      a.orderingNumber - b.orderingNumber

    return {
      [SCHOOL_TYPE.초등학교]: commonRepo.curriculumTreeColl?.toElementaryArr
        .sort(sortByOrderingNumber)
        .filter((c) => {
          return (
            !c.korSchoolGradeSemester.includes('2-') && !c.korSchoolGradeSemester.includes('1-')
          )
        }),
      [SCHOOL_TYPE.중학교]: commonRepo.curriculumTreeColl?.toMiddleArr
        .filter(({ trieKey }) => !EXCLUDED_CURRICULUM_KEY.includes(trieKey))
        .sort(sortByOrderingNumber),
      [SCHOOL_TYPE.고등학교]: commonRepo.curriculumTreeColl?.toHighArr
        .filter(({ trieKey }) => !EXCLUDED_CURRICULUM_KEY.includes(trieKey))
        .sort(sortByOrderingNumber),
    }
  }

  get flatAndSelectableCurriculumList() {
    return Object.values(this.selectableCurriculumList)
      .flat()
      .filter((v) => !!v) as CurriculumInfo[]
  }

  fetchLectureGroups = async (
    trieKey: CurriculumEntity.CurriculumTree['trieKey'],
    studentId: StudentDomain.Id,
  ) => {
    return await Promise.all([
      lectureApi.getLectureByTrieKey(trieKey),
      lectureApi.getDoneLectureIdByTrieKey({ studentId, trieKey }),
    ])
      .then(([lectures, doneLectureIds]) => {
        const lectureGroups = lectures.map(({ leadingCurriculum, middleChapterLectures }) => ({
          leadingCurriculum,
          middleChapterLectures: middleChapterLectures.flatMap(({ curriculum, lectures }) =>
            lectures.map(
              (lecture) =>
                new MiddleChapterLecture({
                  ...lecture,
                  isDone: doneLectureIds.includes(lecture.id),
                  bigChapterName: leadingCurriculum.name,
                  middleChapterName: curriculum.name,
                  trieKey: lecture.curriculum.key,
                }),
            ),
          ),
        }))
        return lectureGroups
      })
      .catch((e) => {
        console.error(e)
        throw e
      })
      .finally(() => {
        this.updateIsFetching(false)
      })
  }

  fetchSetLectureGroups = async (
    {
      trieKey,
      studentId,
    }: {
      trieKey: CurriculumEntity.CurriculumTree['trieKey']
      studentId: StudentDomain.Id
    },
    breakdown: CurriculumEntity.Curriculum['type'] = 'BIG_CHAPTER',
  ) => {
    try {
      this.updateIsFetching(true)

      const lectureGroups = await this.fetchLectureGroups(trieKey, studentId)
      if (breakdown === 'BIG_CHAPTER') {
        runInAction(() => {
          this.lectureGroups = lectureGroups
        })
        return
      }
      // MIDDLE_CHAPTER
      const middleChapterLecturesList = lectureGroups.flatMap((lectureGroup) => {
        return Object.values(groupBy(lectureGroup.middleChapterLectures, 'trieKey'))
      })

      runInAction(() => {
        this.lectureGroups = middleChapterLecturesList.map((middleChapterLectures) => ({
          leadingCurriculum: {
            id: middleChapterLectures[0].id,
            name: middleChapterLectures[0].middleChapterName,
            type: 'MIDDLE_CHAPTER',
          },
          middleChapterLectures,
        }))
      })
    } catch (e) {
      console.error(e)
      throw e
    } finally {
      this.updateIsFetching(false)
    }
  }

  setLectureGroups = (lectureGroups: LectureGroup[]) => {
    this.lectureGroups = lectureGroups
  }

  static findLectureGroupByLectureId = (
    lectureGroups: LectureGroup[],
    lectureId: MiddleChapterLecture['id'],
  ) => {
    return lectureGroups.find((lectureGroup) =>
      lectureGroup.middleChapterLectures.find((lecture) => lecture.id === lectureId),
    )
  }

  getLectureGroupById = async (
    lectureId: MiddleChapterLecture['id'],
    studentId: StudentDomain.Id,
  ) => {
    let lectureGroups = this.lectureGroups
    if (!lectureGroups) {
      const lecture = await lectureApi.getLectureByLectureId(lectureId)

      // 대단원 목록을 조회해야하는데, 대단원 조회할 커리큘럼 키를 몰라서, 중단원키에서 대단원키를 추출하는 과정을 거침
      // 제약조건: 지금이 중단원일 때만 가능하게 함
      if (lecture.curriculum.type !== 'MIDDLE_CHAPTER') {
        throw Error('중단원 조회가 아닙니다.')
      }

      const bigChapterCurriculumKey = curriculumUtils.extractUpperCurriculumKey(
        lecture.curriculum.key,
        lecture.curriculum.id,
      )

      lectureGroups = await this.fetchLectureGroups(bigChapterCurriculumKey, studentId)
    }
    return LectureService.findLectureGroupByLectureId(lectureGroups, lectureId)
  }

  setProgress = async ({
    studentId,
    lecture,
  }: {
    studentId: StudentDomain.Id
    lecture: MiddleChapterLecture
  }) => {
    await lectureApi.postStudentLecture({ studentId, lectureId: lecture.id })
  }

  updateIsFetching(fetchingStatus: boolean) {
    this.isFetching = fetchingStatus
  }
}

export class MiddleChapterLecture extends LectureEntity.Lecture {
  isDone: boolean
  bigChapterName: CurriculumEntity.Curriculum['name']
  middleChapterName: CurriculumEntity.Curriculum['name']
  trieKey: CurriculumEntity.Curriculum['key']

  constructor(
    params: LectureEntity.Lecture & {
      isDone: boolean
      bigChapterName: CurriculumEntity.Curriculum['name']
      middleChapterName: CurriculumEntity.Curriculum['name']
      trieKey: CurriculumEntity.Curriculum['key']
    },
  ) {
    super(params)
    this.isDone = params.isDone
    this.bigChapterName = params.bigChapterName
    this.middleChapterName = params.middleChapterName
    this.trieKey = params.trieKey
  }
}
