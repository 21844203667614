import { SCHOOL_TYPE_KO_ABBR, SUBJECT_HIGH_SHORT } from './constants'
import type {
  AllGradeSubject,
  GradeElementaryMiddle,
  GradeElementaryMiddleAbbr,
  GradeHigh,
  SchoolType,
  Semester,
} from './schema'

export class SchoolSystem {
  static getKoreanElementaryMiddleGradeAbbr(
    grade: AllGradeSubject,
    schoolType: SchoolType,
  ): GradeElementaryMiddleAbbr | GradeHigh {
    if (!isNaN(Number(grade))) {
      const koSchoolTypeAbbr = SCHOOL_TYPE_KO_ABBR[schoolType]
      return `${koSchoolTypeAbbr}${grade}` as GradeElementaryMiddleAbbr // 초1, 중1, 고1
    } else {
      return grade
    }
  }

  static getKoreanSchoolType(schoolType: SchoolType) {
    return SCHOOL_TYPE_KO_ABBR[schoolType]
  }

  static getKoreanHighSubjectShort(subject: GradeHigh) {
    return SUBJECT_HIGH_SHORT[subject]
  }

  static getKoreanCommonGradeSemester(option: {
    grade: AllGradeSubject
    semester: Semester
    schoolType: SchoolType
  }) {
    const koSchoolTypeAbbr = SCHOOL_TYPE_KO_ABBR[option.schoolType]
    const koGradeAbbr = SchoolSystem.getKoreanElementaryMiddleGradeAbbr(
      option.grade as GradeElementaryMiddle,
      option.schoolType,
    )
    switch (option.schoolType) {
      case 'ELEMENTARY':
      case 'MIDDLE':
        return {
          koSchoolType: koSchoolTypeAbbr,
          koGrade: koGradeAbbr,
          grade: option.grade,
          semester: option.semester,
          koSubject: null,
        }
      case 'HIGH':
      default:
        // if (!isNaN(Number(option.grade))) throw new Error('고등학교는 grade가 숫자일 수 없습니다.')
        return {
          koSchoolType: koSchoolTypeAbbr,
          koGrade: koGradeAbbr,
          grade: null,
          semester: null,
          koSubject: SUBJECT_HIGH_SHORT[option.grade as GradeHigh],
        }
    }
  }
}
