import type { Entity } from '@mathflat/domain/@entities/Problem/dto'
import { observer } from 'mobx-react'

import S from './ProblemScoringCard.style'

const ProblemScoringBody = observer(
  ({
    문제이미지,
    문제같이보기,
  }: {
    문제이미지?: Entity.Problem['problemImageUrl']
    문제같이보기?: boolean
  }) => {
    if (!문제이미지 || !문제같이보기) return <></>
    return (
      <S.ProblemScoringContent className="problem-content">
        <div className="problem-content-container">
          <img className="problem-image" src={문제이미지} alt="problem-image" />
        </div>
      </S.ProblemScoringContent>
    )
  },
)

export default ProblemScoringBody
