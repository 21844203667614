import 'simplebar-react/dist/simplebar.min.css'

import type { HandwrittenNoteTypes } from '@mathflat/handwritten-note'
import { ReadOnlyHandwrittenNote } from '@mathflat/handwritten-note'
import { s3URL } from '@mathflat/shared/@common/utils/s3'
import { clsx } from 'clsx'
import MathflatPlayer from 'mathflat-videoplayer'
import { useEffect, useMemo, useState } from 'react'
import SimpleBar from 'simplebar-react'

import { handwrittenNoteApi } from '~/@common/api/handwrittenNoteApi'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import modalService from '~/@common/services/modal.service'
import { colors } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'
import ProblemNoteMobileViewer from '~/@pages/student/@widgets/ProblemNote/ProblemNoteMobileViewer'
import type { StudentWorkbookDetailService } from '~/@pages/student/student-workbook/detail/@service/StudentWorkbookDetail.service'

import type { ProblemScoring } from '../../../@trait/ProblemScoring.trait'
import type { ProblemScoringViewOption } from '../../../@trait/ProblemScoringViewOption.trait'
import { S } from './AnswerVideoDrawer.style'

type AnswerVideoDrawerProps = {
  problemScoring: ProblemScoring<'WORKSHEET' | 'WORKBOOK'>
  viewOption: ProblemScoringViewOption<'학습모듈' | 'NOT_학습모듈'>
  isSubmittedAnswerAvailable?: boolean
  openDrawer: boolean
  onSubmitVideoAssist?: StudentWorkbookDetailService['onSubmitVideoAssist']
}
export const AnswerVideoDrawer = ({
  problemScoring,
  viewOption,
  isSubmittedAnswerAvailable,
  openDrawer,
  onSubmitVideoAssist,
}: AnswerVideoDrawerProps) => {
  const { isMobile } = useStudentAppMediaQuery()
  const [_isMobile, setIsMobile] = useState(isMobile)
  const [isOrientLocked, setIsOrientLocked] = useState(false)
  const [isShowProblemImage, setIsShowProblemImage] = useState(false)
  const [isHiddenNoteData, setIsHiddenNoteData] = useState(false)
  const [noteData, setNoteData] = useState<HandwrittenNoteTypes.NoteData | null>(null)
  const [videoPlaybackTime, setVideoPlaybackTime] = useState(0)
  const isCustomSignature = viewOption.content.type === 'CUSTOM_SIGNATURE'

  useEffect(() => {
    if (!openDrawer) {
      setVideoPlaybackTime(0)
      return
    }
    if (isOrientLocked) return
    setIsMobile(isMobile)
  }, [isOrientLocked, isMobile, openDrawer])

  useEffect(() => {
    if (openDrawer && problemScoring.handwrittenNoteUrl) {
      handwrittenNoteApi
        .fetchNoteByUrl(problemScoring.handwrittenNoteUrl)
        .then((data) => {
          setNoteData(data)
        })
        .catch((e) => {
          console.error(e)
        })
    } else {
      setNoteData(null)
    }
  }, [problemScoring.handwrittenNoteUrl, openDrawer])

  const onCloseProblemNoteMobileViewer = () => {
    modalService.closeModal()
  }

  const openNoteViewer = () => {
    if (noteData && problemScoring.문제이미지) {
      modalService.openModal(
        <ProblemNoteMobileViewer
          noteData={noteData}
          problemImgUrl={problemScoring.문제이미지}
          onClose={onCloseProblemNoteMobileViewer}
        />,
        { modalName: '내풀이 확대 모달' },
      )
    }
  }

  const toggleHiddenNoteData = () => {
    setIsHiddenNoteData((prev) => !prev)
  }

  const closeDrawer = () => {
    setIsHiddenNoteData(false)
  }

  const isMAAT = viewOption.content.type === 'MAAT'

  const 정답해설_공개 =
    !isMAAT &&
    problemScoring.isSubmitted &&
    (!viewOption.studentAppSetting || viewOption.studentAppSetting.채점후정답해설공개)

  const 문풀동공개 = (() => {
    if (isMAAT) return false
    if (!viewOption.studentAppSetting) return true
    return (
      (!problemScoring.isSubmitted && viewOption.studentAppSetting?.채점전문풀동공개) ||
      (problemScoring.isSubmitted && viewOption.studentAppSetting?.채점후문풀동공개)
    )
  })()

  const onPlayCallback = () => {
    if ('video' in problemScoring.problem && problemScoring.problem.video?.id) {
      const trackingInfo = (() => {
        if (viewOption.content.kind === 'WORKSHEET' && viewOption.content.type === 'CUSTOM') {
          return {
            trackKey: '[내학습지] 빠른채점_문풀동_재생',
            trackData: {
              '학제/학년': problemScoring.학제학년학기,
            },
          }
        }
        if (viewOption.content.kind === 'WORKBOOK') {
          if (viewOption.content.type === 'CUSTOM') {
            return {
              trackKey: '[내교재] 풀이_문풀동_재생',
              trackData: {
                '학년/학기': problemScoring.학제학년학기,
              },
            }
          }
          if (viewOption.content.type === 'CUSTOM_SIGNATURE') {
            return {
              trackKey: '[시그니처교재] 풀이_문풀동_재생',
              trackData: {
                '학년/학기': problemScoring.학제학년학기,
              },
            }
          }
        }
        return null
      })()

      if (trackingInfo && problemScoring.problem.video.id && problemScoring.problemId) {
        window.freshpaint?.track(trackingInfo.trackKey, {
          비디오id: problemScoring.problem?.video?.id,
          문제id: problemScoring.problemId,
          정오: problemScoring.채점결과 === 'NONE' ? null : problemScoring.채점결과,
          ...trackingInfo.trackData,
        })
      }
    }

    if (!onSubmitVideoAssist) return
    if (!problemScoring.isSubmitted && viewOption.studentAppSetting?.채점전문풀동공개) {
      onSubmitVideoAssist({
        workbookProblemId: problemScoring.contentProblemId,
        assistType: 'WATCHED_SOLVING_VIDEO_BEFORE_SCORING',
      })
    }
    if (problemScoring.isSubmitted && viewOption.studentAppSetting?.채점후문풀동공개) {
      onSubmitVideoAssist({
        workbookProblemId: problemScoring.contentProblemId,
        assistType: 'WATCHED_SOLVING_VIDEO_AFTER_SCORING',
      })
    }
  }
  const emptyCase = useMemo(() => {
    if (!isSubmittedAnswerAvailable) {
      return <></>
    }

    if (!problemScoring.handwrittenNoteUrl && _isMobile) {
      return <div className="mobile-text">필기한 기록이 없어요</div>
    }

    return (
      <SimpleBar
        key="without-note-data"
        style={{
          maxHeight: 정답해설_공개 || 문풀동공개 ? '312px' : '100%',
        }}
      >
        <div className="problem-image-container">
          <img src={problemScoring.문제이미지} alt="문제 이미지" width={344} />
        </div>
      </SimpleBar>
    )
  }, [problemScoring.handwrittenNoteUrl, isSubmittedAnswerAvailable])

  const 해설내풀이문구_상세명 = useMemo(() => {
    return (
      <>
        <span className="explation-submitted-answer-text">풀이동영상</span>
        {problemScoring.isSubmitted && isSubmittedAnswerAvailable && (
          <span className="explation-submitted-answer-text">문제 & 내풀이</span>
        )}
        {!!problemScoring.문제해설이미지 && (
          <span className="explation-submitted-answer-text">해설</span>
        )}
      </>
    )
  }, [
    problemScoring.isSubmitted,
    !viewOption.studentAppSetting,
    viewOption.studentAppSetting?.채점후정답해설공개,
    problemScoring.문제해설이미지,
    isSubmittedAnswerAvailable,
  ])

  return (
    <Drawer
      size="534px"
      isOpened={openDrawer}
      placement={_isMobile ? 'bottom' : 'left'}
      backdropOpacity={_isMobile ? 1 : 0}
      closeDrawer={closeDrawer}
      {...(!_isMobile && { rootSelector: '#app', position: 'absolute' })}
    >
      <Drawer.Content css={S.problemScoringDrawerContentStyle}>
        <Drawer.Header className="problem-scoring-drawer-header">
          {해설내풀이문구_상세명}
        </Drawer.Header>
        <Drawer.Body className="problem-scoring-drawer-body">
          <S.DrawerBodyContent
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            {!isMAAT && (
              <div className="problem-scoring-drawer-body-content-item">
                <div className="title">풀이동영상</div>
                {!문풀동공개 ? (
                  <div className="no-video">
                    {problemScoring.isSubmitted && '채점 후 '}
                    풀이 영상이 비공개 상태입니다.
                  </div>
                ) : (
                  <>
                    {'video' in problemScoring.problem && problemScoring.problem.video ? (
                      <MathflatPlayer
                        onFullScreenCallback={() => {
                          setIsOrientLocked(true)
                        }}
                        onChangeFullScreenState={(state: boolean) => {
                          if (!state) {
                            setIsOrientLocked(false)
                          }
                        }}
                        ThumbnailElement={
                          !isCustomSignature ? (
                            <S.Thumbnail>
                              <div className="img-bg">
                                {problemScoring.문제이미지 && (
                                  <img src={problemScoring.문제이미지} style={{ width: '100%' }} />
                                )}
                              </div>
                              <div className="gradation">
                                <img
                                  className="sub-title"
                                  src={s3URL.common(
                                    'images/video/problem-solving/sub-title_student-app@1x.png',
                                  )}
                                  srcSet={`${s3URL.common(
                                    'images/video/problem-solving/sub-title_student-app@2x.png',
                                  )} 2x, ${s3URL.common('images/video/problem-solving/sub-title_student-app@3x.png')} 3x`}
                                />
                                <img
                                  className="title"
                                  src={s3URL.common('images/video/problem-solving/title@1x.png')}
                                  srcSet={`${s3URL.common(
                                    'images/video/problem-solving/title@2x.png',
                                  )} 2x, ${s3URL.common('images/video/problem-solving/title@3x.png')} 3x`}
                                />
                              </div>
                            </S.Thumbnail>
                          ) : null
                        }
                        videoUrl={problemScoring.problem.video?.videoUrl as string}
                        onPlayCallback={onPlayCallback}
                        subtitleUrl={problemScoring.problem.video?.subtitleUrl ?? undefined}
                        defaultPlayingTime={videoPlaybackTime}
                        onProgressCallback={(time) => {
                          setVideoPlaybackTime(time)
                        }}
                      />
                    ) : (
                      <div className="no-video">풀이동영상이 없어요</div>
                    )}
                  </>
                )}
              </div>
            )}

            {problemScoring.isSubmitted && problemScoring.문제이미지 && _isMobile && (
              <div className="problem-scoring-drawer-body-content-item">
                <div className="title-wrapper">
                  <div className="title">문제</div>
                  <button
                    className={clsx('icon-button', isShowProblemImage && 'opened')}
                    onClick={() => setIsShowProblemImage((prev) => !prev)}
                  >
                    <Icon name="icon_chevron_down" size={20} color={colors.gray.$900} />
                  </button>
                </div>
                {isShowProblemImage && (
                  <img src={problemScoring.문제이미지} alt="문제 이미지" width={344} />
                )}
              </div>
            )}

            {problemScoring.isSubmitted && isSubmittedAnswerAvailable && (
              <div className="problem-scoring-drawer-body-content-item">
                <div className="problem-scoring-drawer-body-content-item-header">
                  <div className="title">{!_isMobile && '문제 ・ '} 내풀이</div>
                  {!_isMobile ? (
                    noteData ? (
                      <button onClick={toggleHiddenNoteData} className="icon-button">
                        <Icon
                          name={isHiddenNoteData ? 'icon_eye_closed' : 'icon_eye_opened'}
                          size={22}
                          color={colors.gray.$800}
                        />
                      </button>
                    ) : (
                      <p className="no-writing">필기한 기록이 없어요</p>
                    )
                  ) : (
                    <></>
                  )}
                </div>
                {noteData &&
                problemScoring.handwrittenNoteUrl &&
                problemScoring.문제이미지 &&
                !isHiddenNoteData ? (
                  <SimpleBar
                    key="with-note-data"
                    style={{
                      maxHeight: 정답해설_공개 || 문풀동공개 ? '312px' : '100%',
                    }}
                  >
                    <div className="note-container">
                      <ReadOnlyHandwrittenNote
                        noteData={noteData}
                        preventScale
                        preventOverflow
                        isSlideToNoteRect={_isMobile}
                      >
                        <img src={problemScoring.문제이미지} alt="문제 이미지" width={344} />
                      </ReadOnlyHandwrittenNote>
                      {_isMobile && (
                        <button type="button" className="note-zoom-in" onClick={openNoteViewer}>
                          <Icon name="icon_zoom_in" size={16} />
                        </button>
                      )}
                    </div>
                  </SimpleBar>
                ) : (
                  emptyCase
                )}
              </div>
            )}

            <div className="problem-scoring-drawer-body-content-item">
              <div className="title">해설</div>
              {정답해설_공개 ? (
                <img src={problemScoring.문제해설이미지} alt="문제 해설 이미지" width={344} />
              ) : (
                <>
                  {!isMAAT ? (
                    <div className="no-answer">
                      {viewOption.studentAppSetting?.채점후정답해설공개 && (
                        <>채점 후 정답・해설이 공개됩니다.</>
                      )}
                      {!viewOption.studentAppSetting?.채점후정답해설공개 && (
                        <>채점 후 정답・해설이 비공개 상태입니다.</>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </div>
          </S.DrawerBodyContent>
        </Drawer.Body>
      </Drawer.Content>
    </Drawer>
  )
}
