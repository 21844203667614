import type { VisualEditorDataT } from '@mathflat/visual-editor/ui/_VisualEditor.js'
import { cloneDeep } from 'lodash'

import type { DesignTemplateResponse } from '../api'
import { COVER_DATA_TYPE, type CoverDataType } from '../constant'

export class CoverData<PreAssignedCoverData extends Record<string, CoverDataItem | null>> {
  // api response는 편의를 위해 coverData에 필수값+커스텀값 다 담겨 오고,
  // client에서는 필수값과 커스텀값을 나눠서 사용하는게 편하기 때문에 여기 분리
  // 이 dto의 constructor에서 api response받아도 되고, 자기자신도 받을 수 있도록 분기
  preAssignedCoverData: MappedCoverData<PreAssignedCoverData>
  customCoverData: CoverDataItem[]

  width?: number
  height?: number

  constructor(
    data:
      | DesignTemplateResponse<PreAssignedCoverData>['coverData']
      | CoverData<PreAssignedCoverData>,
  ) {
    if (data instanceof CoverData) {
      this.preAssignedCoverData = cloneDeep(data.preAssignedCoverData)
      this.customCoverData = cloneDeep(data.customCoverData)
      this.width = data.width
      this.height = data.height
    } else {
      const { customData, width, height, ...requiredCoverData } = data
      const coverData = {}
      if (width) {
        this.width = Number(width)
      }
      if (height) {
        this.height = Number(height)
      }
      Object.entries(requiredCoverData).forEach(([key, value]) => {
        if (value === null) {
          coverData[key] = null
        } else {
          coverData[key] = createCoverDataItem(value)
        }
      })
      this.preAssignedCoverData =
        coverData as CoverData<PreAssignedCoverData>['preAssignedCoverData']

      this.customCoverData = customData.map((customCoverDataItem) =>
        createCoverDataItem(customCoverDataItem),
      )
    }
  }

  getPreAssignedItem = <K extends keyof PreAssignedCoverData>(key: K) => {
    if (!(key in this.preAssignedCoverData)) {
      throw Error('해당 키가 존재하지 않습니다.')
    }
    return this.preAssignedCoverData[key]
  }

  getCustomItem = (key: CoverDataItem['id']): CoverDataItem | undefined => {
    return this.customCoverData.find((v) => v.id === key)
  }

  getItem = <K extends keyof MappedCoverData<PreAssignedCoverData>>(
    key: K | CoverDataItem['id'],
  ) => {
    if (key in this.preAssignedCoverData) {
      return this.getPreAssignedItem(key as K)
    }

    return this.getCustomItem(key as CoverDataItem['id'])
  }

  get allItems() {
    return toAllFilteredCoverItems({
      coverData: this.preAssignedCoverData,
      customCoverData: this.customCoverData,
    })
  }

  getPayload = () => {
    if (!this.width || !this.height) {
      throw Error('필수 값입니다.')
    }
    const preAssignedCoverDataPayload = Object.fromEntries(
      Object.entries(this.preAssignedCoverData).map(([key, value]) => {
        return [key, value?.getPayload() ?? null]
      }),
    ) as CoverData<PreAssignedCoverData>['preAssignedCoverData']

    const customDataPayload = this.customCoverData.map((v) => v.getPayload()) as CoverDataItem[]

    return {
      ...preAssignedCoverDataPayload,
      customData: customDataPayload,
      width: String(this.width),
      height: String(this.height),
    }
  }
}

function createCoverDataItem(data: CoverDataItem) {
  switch (data.type) {
    case COVER_DATA_TYPE.TEXT:
      return new TextCoverDataItem(data)
    case COVER_DATA_TYPE.IMAGE:
      return new ImageCoverDataItem(data)
    case COVER_DATA_TYPE.RECT:
      return new RectCoverDataItem(data)
    case COVER_DATA_TYPE.LINE:
      return new LineCoverDataItem(data)
    case COVER_DATA_TYPE.EDITABLE_IMAGE:
      return new EditableImageCoverDataItem(data)
  }
}

// 커버데이터의 한 아이템은 이렇게 생겼습니다. 이걸 사용한 곳에서는 coverDataItem이란 느낌의 이름을 가집니다.
export type CoverDataItem<
  T extends CoverDataType = CoverDataType,
  K extends string = string,
> = T extends typeof COVER_DATA_TYPE.TEXT
  ? TextCoverDataItem<K>
  : T extends typeof COVER_DATA_TYPE.IMAGE
    ? ImageCoverDataItem<K>
    : T extends typeof COVER_DATA_TYPE.EDITABLE_IMAGE
      ? EditableImageCoverDataItem<K>
      : T extends typeof COVER_DATA_TYPE.LINE
        ? LineCoverDataItem<K>
        : RectCoverDataItem<K>

export class TextCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.TEXT>
{
  type: typeof COVER_DATA_TYPE.TEXT
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.TEXT>['props']
  id: K
  order: number

  constructor(data: TextCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export class ImageCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.IMAGE>
{
  type: typeof COVER_DATA_TYPE.IMAGE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.IMAGE>['props']
  id: K
  order: number

  constructor(data: ImageCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
  getPayload() {
    return this
  }
}
export class EditableImageCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.EDITABLE_IMAGE>
{
  type: typeof COVER_DATA_TYPE.EDITABLE_IMAGE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.EDITABLE_IMAGE>['props']
  id: K
  order: number

  constructor(data: EditableImageCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }
  getPayload() {
    const {
      props: { UploadFileButton, ...restProps },
      ...rest
    } = this
    return { ...rest, props: restProps }
  }
}
export class RectCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.RECT>
{
  type: typeof COVER_DATA_TYPE.RECT
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.RECT>['props']
  id: K
  order: number

  constructor(data: RectCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export class LineCoverDataItem<K extends string = string>
  implements VisualEditorDataT<typeof COVER_DATA_TYPE.LINE>
{
  type: typeof COVER_DATA_TYPE.LINE
  props: VisualEditorDataT<typeof COVER_DATA_TYPE.LINE>['props']
  id: K
  order: number

  constructor(data: LineCoverDataItem<K>) {
    this.type = data.type
    this.props = data.props
    this.id = data.id
    this.order = data.order
  }

  getPayload() {
    return this
  }
}

export const toAllFilteredCoverItems = ({
  coverData,
  customCoverData,
}: {
  coverData: Record<string, CoverDataItem | null>
  customCoverData: CoverDataItem[]
}) => {
  return [...(customCoverData ?? []), Object.values(coverData ?? [])]
    .flat()
    .filter((v) => !!v) as CoverDataItem[]
}

export type MappedCoverData<T extends Record<string, CoverDataItem | null>> = {
  [K in keyof T]: T[K] extends CoverDataItem
    ? T[K]['type'] extends infer CT
      ? CT extends CoverDataType
        ? CoverDataItem<CT, K & string>
        : never
      : never
    : T[K]
}

export const COVER_BINDING_AREA_RECT_ID = 'COVER_BINDING_AREA'
export const COVER_BACKGROUND_ID = 'COVER_BACKGROUND'
export const COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION_ID =
  'COVER_BACK_DEFAULT_TEXT_MATHFLAT_PRODUCTION'
export const COVER_BACK_DEFAULT_TEXT_COPYRIGHT_ID = 'COVER_BACK_DEFAULT_TEXT_COPYRIGHT'
