import styled from '@emotion/styled'
import { SCHOOL_TYPE } from '@mathflat/domain/@entities/SchoolSystem/constants'
import type { Entity as CurriculumEntity } from '@mathflat/domain/@entities/Curriculum/dto'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'
import { computed } from 'mobx'
import { observer, useLocalObservable } from 'mobx-react'
import { isMobileOnly } from 'react-device-detect'

import useScrollNavigation, {
  NAV_BUTTON_STATE,
  SWIPE_STATE,
} from '~/@app/hooks/useScrollNavigation'
import { DROPDOWN_ITEM_HEIGHT } from '~/@common/constants'
import { useStudentAppMediaQuery } from '~/@common/hooks/useMediaQuery'
import { useOrientation } from '~/@common/hooks/useOrientation'
import { useRepository } from '~/@common/hooks/useRepository'
import { commonRepo } from '~/@common/services/repo.service'
import { SwiperDefaultBackButton, SwiperDefaultNextButton } from '~/@common/ui/(StyledButton)'
import { DropdownItem } from '~/@common/ui/Dropdown'
import Dropdown from '~/@common/ui/Dropdown/Dropdown'
import { Chip } from '~/@common/ui/radio/Chip'

import { LectureService } from '../@service/Lecture.service'
import { LECTURE_GROUP_CARD_SIZE } from './LectureGroup.style'
import { LECTURE_GROUP_LIST_ID } from './LectureGroupList.widget'

const LectureGroupHeader = () => {
  const service = useRepository(LectureService)
  const { isMobile } = useStudentAppMediaQuery()
  const { type: orientationType } = useOrientation()

  const _store = useLocalObservable(() => ({
    selectedSchoolType: SCHOOL_TYPE.중학교 as ValueOf<typeof SCHOOL_TYPE>,
    selectedCurriculumTrieKey: commonRepo.curriculumTreeColl?.toMiddleArr[0].trieKey,

    onChangeCurriculumDropdown: (trieKey: CurriculumEntity.CurriculumTree['trieKey']) => {
      if (!commonRepo.studentId) throw new Error('studentId is null')

      const curriculumInfo =
        commonRepo.curriculumTreeColl?.getCurriculumInfoByCurriculumKey(trieKey)

      if (!curriculumInfo) throw Error('커리큘럼 정보를 찾을 수 없습니다.')

      service.setCurriculumAndFetchLectureGroups({
        curriculumInfo,
        studentId: commonRepo.studentId,
      })
    },
  }))

  const selectedDropdownList = computed(() => {
    if (!service.selectedCurriculumInfo) return null

    if (isMobile) return service.flatAndSelectableCurriculumList

    return service.selectableCurriculumList[service.selectedCurriculumInfo.schoolType]
  }).get()

  const isMobileLandscape = isMobileOnly && orientationType === 'landscape'

  const { handleSwipe, nextButtonState, prevButtonState } = useScrollNavigation(
    { elementId: LECTURE_GROUP_LIST_ID, distance: LECTURE_GROUP_CARD_SIZE },
    [service.lectureGroups],
  )

  if (!service.selectedCurriculumInfo) {
    return <></>
  }

  return (
    <S.Header>
      <div className="left">
        {!isMobile && <span>학년선택</span>}
        <div className="curriculum-list">
          {!isMobile && (
            <div className="school-type-group">
              <Chip.Group
                onChange={(v) => {
                  service.setSelectSchoolType(v as ValueOf<typeof SCHOOL_TYPE>)
                }}
                value={service.selectedCurriculumInfo.schoolType}
              >
                <Chip.GroupItem value={SCHOOL_TYPE.초등학교}>초</Chip.GroupItem>
                <Chip.GroupItem value={SCHOOL_TYPE.중학교}>중</Chip.GroupItem>
                <Chip.GroupItem value={SCHOOL_TYPE.고등학교}>고</Chip.GroupItem>
              </Chip.Group>
            </div>
          )}
          <Dropdown
            width={160}
            listHeight={isMobileLandscape ? DROPDOWN_ITEM_HEIGHT * 3 : undefined}
            value={service.selectedCurriculumInfo.trieKey}
            onChange={(curriculumKey) => _store.onChangeCurriculumDropdown(curriculumKey)}
          >
            {selectedDropdownList?.map((schoolCurriculum) => (
              <DropdownItem key={schoolCurriculum.trieKey} value={schoolCurriculum.trieKey}>
                {schoolCurriculum.korSchoolGradeSemester}
              </DropdownItem>
            ))}
          </Dropdown>
        </div>
      </div>
      {!isMobile && service.lectureGroups && service.lectureGroups.length > 0 && (
        <div className="right">
          {prevButtonState !== NAV_BUTTON_STATE.INVISIBLE && (
            <SwiperDefaultBackButton
              size={38}
              theme="white"
              disabled={prevButtonState === NAV_BUTTON_STATE.DISABLED}
              onClick={() => {
                handleSwipe(SWIPE_STATE.PREV)
              }}
            />
          )}
          {nextButtonState !== NAV_BUTTON_STATE.INVISIBLE && (
            <SwiperDefaultNextButton
              size={38}
              theme="white"
              disabled={nextButtonState === NAV_BUTTON_STATE.DISABLED}
              onClick={() => {
                handleSwipe(SWIPE_STATE.NEXT)
              }}
            />
          )}
        </div>
      )}
    </S.Header>
  )
}

export default observer(LectureGroupHeader)

const S = {
  Header: styled.header`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .curriculum-list {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .left {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .right {
      display: flex;
      align-items: center;
      gap: 17px;
    }
  `,
}
