import { css } from '@emotion/react'
import { WorksheetDomain } from '@mathflat/domain/@entities'
import { CONTENT_STATUS } from '@mathflat/domain/@entities/(Content)/module'
import { observer } from 'mobx-react'
import { nanoid } from 'nanoid'
import { useNavigate } from 'react-router'

import { useRepository } from '~/@common/hooks/useRepository'
import { drawerService } from '~/@common/services'
import modalService from '~/@common/services/modal.service'
import { colors, colorTheme, typo } from '~/@common/styles'
import { Drawer } from '~/@common/ui/(Drawer)/BaseDrawer'
import { Icon } from '~/@common/ui/Icon/Icon'
import Modal from '~/@common/ui/modal/Modal'
import { ProgressBar } from '~/@common/ui/ProgressBar/ProgressBar'
import { EmptyCaseIconWithDescription } from '~/@pages/@common/EmptyCase'
import { SelfLearningGroupInnerDrawerContent } from '~/@pages/student/@widgets/SelfLearningGroupInnerDrawerContent/SelfLearningGroupInnerDrawerContent'
import { LearningProcessService } from '~/@pages/student/learning-process/@widgets/service/LearningProcess.service'
import MAATAlreadyCompleteModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/MAATAlreadyCompleteModal'
import StudentMAATExamModal from '~/@pages/student/student-exam/$studentExamId/scoring/@widgets/StudentMAATExamModal'

import {
  StudentCustomWorksheetList,
  StudentExamWorksheetList,
  type StudentSelfLearningWorksheetGroupList,
  type StudentSelfLearningWorksheetList,
  type StudentWorksheetList,
} from '../../@common/model/(StudentWorksheetList)'
import { S } from '../../@common/style/common.style'
import LearningBadge from '../../@common/ui/LearningBadge'
import SelfLearningWorksheetListModal from '../../@common/ui/SelfLearningListModal'
import { SelfLearningTitleTag } from '../../@common/ui/SelfLearningTitleTag'
import { StatusTag } from '../../@common/ui/StatusTag'
import {
  StudentWorksheetCardAsChild,
  StudentWorksheetCardAsParent,
} from '../../@common/ui/StudentWorksheetCard'
import { WrongCorrectCount } from '../../@common/ui/WrongCorrectCount'
import { StudentWorksheetListService } from '../../@service/StudentWorksheetList.service'
import { StudentWorksheetListMobileDropdownFilter } from './StudentWorksheetListMobileDropdownFilter'
import { StudentWorksheetListMobileHeader } from './StudentWorksheetListMobileHeader'

const isMAATStudentWorksheet = (studentWorksheet: StudentWorksheetList) => {
  return (studentWorksheet as StudentExamWorksheetList)?.exam?.examType === 'MAAT'
}
const isMAATUnstartedAndComplete = (studentWorksheet: StudentWorksheetList) => {
  return (
    isMAATStudentWorksheet(studentWorksheet) &&
    !(studentWorksheet as StudentExamWorksheetList)?.openDatetime &&
    studentWorksheet.getStatus() === CONTENT_STATUS.전체채점
  )
}

export const StudentWorksheetListMobileView = observer(() => {
  const { beautifiedStudentWorksheetList, loadStudentWorksheetList } = useRepository(
    StudentWorksheetListService,
  )
  const learningProcessService = useRepository(LearningProcessService)
  const navigate = useNavigate()

  const navigateSelfLearningWorksheet = async (selfLearning: StudentSelfLearningWorksheetList) => {
    try {
      const url = await selfLearning.getSelfLearningRoutePath(
        learningProcessService,
        selfLearning.id.toString(),
      )
      navigate(url)
    } catch (err) {
      modalService.openModal(
        <Modal.Alert
          css={{ width: 307 }}
          confirm={{
            onClick: modalService.closeModal,
            style: {
              background: colorTheme.primary,
            },
            children: '확인',
          }}
        >
          <>
            <Icon name="icon_notification" size={24} style={{ width: '100%' }} />
            <p>
              더 이상 풀 수 있는 문제가 없어요.
              <br />
              다른 보충학습을 시도해주세요!
            </p>
          </>
        </Modal.Alert>,
        {
          modalName: '학습 불가',
        },
      )
      console.error(err)
    }
  }

  const clickParentCard = async (studentWorksheet: StudentWorksheetList) => {
    if (isMAATStudentWorksheet(studentWorksheet)) {
      if (studentWorksheet.getStatus() === CONTENT_STATUS.전체채점) {
        modalService.openModal(<MAATAlreadyCompleteModal />, {
          modalName: 'MAAT제출완료팝업',
        })
        return
      }

      if (studentWorksheet.getStatus() === CONTENT_STATUS.채점전) {
        modalService.openModal(
          <StudentMAATExamModal
            title={studentWorksheet.getTitle()}
            studentExamId={studentWorksheet.id}
          />,
          { modalName: 'MAAT응시확인팝업' },
        )
        return
      }
    }
    if (studentWorksheet instanceof StudentCustomWorksheetList) {
      if (studentWorksheet.worksheet.accessModifierToStudent === 'PRIVATE') {
        modalService.openModal(
          <Modal.Alert
            confirm={{
              onClick: () => {
                modalService.closeModal()
              },
            }}
          >
            선생님이 비공개 한 학습지입니다.
            <br />
            공개하기 전까지는 볼 수 없어요!
          </Modal.Alert>,
          { modalName: '비공개 학습지 안내 모달' },
        )
        return
      }
    }
    navigate(studentWorksheet.getRoute())
  }

  const clickSelfLearningTableRow = async (selfLearning: StudentSelfLearningWorksheetGroupList) => {
    if (selfLearning.자기주도_그룹_여부) {
      drawerService.open(
        <SelfLearningGroupInnerDrawerContent>
          <SelfLearningWorksheetListModal
            selfLearningGroup={selfLearning}
            onClick={(s) => {
              navigateSelfLearningWorksheet(s)
              drawerService.close()
            }}
          />
        </SelfLearningGroupInnerDrawerContent>,
        {
          isOpened: true,
          closeDrawer: () => drawerService.close(),
          size: '596px',
        },
      )
      return
    }

    navigateSelfLearningWorksheet(selfLearning.labelSelfLearning)
  }

  const confirmAssignSelfLearning = async (
    학습타입: '오답' | '심화',
    studentWorksheet: StudentWorksheetList,
  ) => {
    modalService.openModal(
      <Modal.Confirm.Positive
        confirm={{
          children: <p data-track={`${학습타입}학습만들기`}>만들기</p>,
          onClick: async () => {
            try {
              학습타입 === '심화'
                ? await learningProcessService.보충_심화학습지_생성({
                    studentWorksheetId: studentWorksheet.id,
                  })
                : await learningProcessService.보충_오답학습지_생성({
                    studentWorksheetId: studentWorksheet.id,
                  })

              await loadStudentWorksheetList()
              modalService.closeModal()
            } catch (err) {
              modalService.closeModal()
              modalService.openModal(
                <Modal.Alert
                  confirm={{
                    onClick: modalService.closeModal,
                    style: {
                      background: colorTheme.primary,
                    },
                    children: '확인',
                  }}
                >
                  <>
                    <Icon name="icon_notification" size={24} style={{ width: '100%' }} />
                    <p>
                      더 이상 풀 수 있는 문제가 없어요.
                      <br />
                      다른 보충학습을 시도해주세요!
                    </p>
                  </>
                </Modal.Alert>,
                {
                  modalName: '학습 불가',
                },
              )
            }
          },
        }}
      >
        {학습타입} 보충 학습지를 만들까요?
      </Modal.Confirm.Positive>,
      {
        modalName: 학습타입 + '학습 시작',
      },
    )
  }

  return (
    <>
      <Drawer {...drawerService.first.options}>{drawerService.first.children}</Drawer>

      <S.MobileContentsWrapper>
        <StudentWorksheetListMobileHeader />
        <StudentWorksheetListMobileDropdownFilter />

        {beautifiedStudentWorksheetList.length === 0 && (
          <EmptyCaseIconWithDescription>
            <p>학습지가 없습니다.</p>
          </EmptyCaseIconWithDescription>
        )}
        {beautifiedStudentWorksheetList.length !== 0 && (
          <ul css={S.studentWorksheetMobileViewList}>
            {beautifiedStudentWorksheetList.map((studentWorksheet) => {
              const isLevelUpTypeSelfLearningMakeBtnDisabled =
                studentWorksheet.getScore() === 100
                  ? false
                  : studentWorksheet.getScore() === 0 ||
                    !!studentWorksheet
                      .checkIsSelfLearningCtaVisible()
                      ?.checkIsCtaDisabled(WorksheetDomain.SELF_LEARNING_TYPE.심화학습)

              return (
                <li key={nanoid()}>
                  <ul
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 10px;
                    `}
                  >
                    <div
                      css={css`
                        position: relative;
                      `}
                    >
                      <StudentWorksheetCardAsParent
                        onClick={() => clickParentCard(studentWorksheet)}
                        ctaSlot={
                          studentWorksheet.checkIsSelfLearningCtaVisible() && (
                            <S.CtaWrapper>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  confirmAssignSelfLearning('오답', studentWorksheet)
                                }}
                                disabled={studentWorksheet
                                  .checkIsSelfLearningCtaVisible()
                                  ?.checkIsCtaDisabled(
                                    WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
                                  )}
                              >
                                <Icon
                                  name="icon_balloon_x"
                                  size={18}
                                  color={
                                    studentWorksheet
                                      .checkIsSelfLearningCtaVisible()
                                      ?.checkIsCtaDisabled(
                                        WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습,
                                      )
                                      ? colors.gray.$500
                                      : colors.gray.$700
                                  }
                                />
                                오답보충학습지
                              </button>
                              <button
                                onClick={(e) => {
                                  e.stopPropagation()
                                  confirmAssignSelfLearning('심화', studentWorksheet)
                                }}
                                disabled={isLevelUpTypeSelfLearningMakeBtnDisabled}
                              >
                                <Icon
                                  name="icon_graph"
                                  size={18}
                                  color={
                                    isLevelUpTypeSelfLearningMakeBtnDisabled
                                      ? colors.gray.$500
                                      : colors.gray.$700
                                  }
                                />
                                심화보충학습지
                              </button>
                            </S.CtaWrapper>
                          )
                        }
                      >
                        <div css={S.cardStyles}>
                          <div css={S.cardTopStyles}>
                            {studentWorksheet instanceof StudentExamWorksheetList && (
                              <span className="icon">
                                <Icon
                                  name={studentWorksheet.getIconName()}
                                  size={12}
                                  color={colors.blue.$500}
                                />
                                <p>TEST</p>
                              </span>
                            )}
                            <span className="datetime">
                              {studentWorksheet.getFormattedAssignDatetime()}
                            </span>
                            <div className="tags">
                              {studentWorksheet.getLabels().map((label) => (
                                <span key={nanoid()} className="tag">
                                  {label}
                                </span>
                              ))}
                              <StatusTag
                                type={
                                  (studentWorksheet as StudentExamWorksheetList)?.exam?.examType ===
                                  'MAAT'
                                    ? 'MAAT'
                                    : 'default'
                                }
                                contentStatus={
                                  isMAATUnstartedAndComplete(studentWorksheet) &&
                                  studentWorksheet.getStatus() === 'COMPLETE'
                                    ? 'IMPOSSIBLE'
                                    : studentWorksheet.getStatus()
                                }
                              />
                            </div>
                          </div>
                          <div
                            css={[
                              S.titleStyles,
                              isMAATUnstartedAndComplete(studentWorksheet) && S.disabledStyles,
                            ]}
                          >
                            {studentWorksheet.getTitle()}
                          </div>
                          <div css={S.cardBottomStyles}>
                            <div css={S.cardBottomLeftStyles}>
                              <span
                                css={[
                                  S.cardBottomProblemCountStyles,
                                  isMAATUnstartedAndComplete(studentWorksheet) && S.disabledStyles,
                                ]}
                              >
                                {studentWorksheet.getProblemCount().toLocaleString()}문제
                              </span>

                              {isMAATStudentWorksheet(studentWorksheet) &&
                                studentWorksheet.getStatus() === CONTENT_STATUS.전체채점 && (
                                  <>
                                    <div className="vertical-divider" />
                                    <span css={S.cardBottomProblemCountStyles}>채점 비공개</span>
                                  </>
                                )}
                              {!isMAATStudentWorksheet(studentWorksheet) &&
                                (studentWorksheet.getStatus() === CONTENT_STATUS.전체채점 ? (
                                  <>
                                    <div className="vertical-divider" />
                                    <span className="point">
                                      점수 {studentWorksheet.getScore()}점
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <div className="vertical-divider" />
                                    <span css={S.cardBottomProblemCountStyles}>채점 전</span>
                                  </>
                                ))}
                            </div>

                            {!isMAATStudentWorksheet(studentWorksheet) &&
                              studentWorksheet.getStatus() === CONTENT_STATUS.전체채점 && (
                                <div css={S.cardBottomRightStyles}>
                                  <WrongCorrectCount
                                    wrongCount={studentWorksheet.getWrongCount()}
                                    correctCount={studentWorksheet.getCorrectCount()}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </StudentWorksheetCardAsParent>
                      <div
                        css={css`
                          position: absolute;
                          display: flex;
                          gap: 6px;
                          right: 10px;
                          top: 0;
                        `}
                      >
                        <div
                          css={css`
                            position: relative;
                          `}
                        >
                          {studentWorksheet.checkIsSelfLearningCtaVisible()
                            ?.isWrongTypeSelfLearningCompleted && (
                            <div
                              css={css`
                                position: absolute;
                                top: 0;
                                right: 36px;
                              `}
                            >
                              <LearningBadge
                                type={WorksheetDomain.SELF_LEARNING_TYPE.오답유형학습}
                              />
                            </div>
                          )}
                          {studentWorksheet.checkIsSelfLearningCtaVisible()
                            ?.isLevelUpTypeSelfLearningCompleted && (
                            <div
                              css={css`
                                position: absolute;
                                top: 0;
                                right: 0;
                              `}
                            >
                              <LearningBadge type={WorksheetDomain.SELF_LEARNING_TYPE.심화학습} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    {studentWorksheet.getSelfLearning()?.map((selfLearning) => (
                      <li key={nanoid()}>
                        <StudentWorksheetCardAsChild
                          onClick={() => clickSelfLearningTableRow(selfLearning)}
                        >
                          <div
                            css={[
                              S.cardStyles,
                              css({
                                padding: selfLearning.자기주도_그룹_여부
                                  ? '14px 20px 8px'
                                  : '14px 20px',
                              }),
                            ]}
                          >
                            <div css={S.cardTopStyles}>
                              <span className="datetime">
                                {selfLearning.getFormattedAssignDatetime()}
                              </span>
                              <SelfLearningTitleTag
                                selfLearningType={
                                  selfLearning.labelSelfLearning.worksheet.selfLearningType
                                }
                                titleTag={selfLearning.labelSelfLearning.worksheet.titleTag}
                              />
                              <span className="tags">
                                {selfLearning.getLabels().map((tag) => (
                                  <span key={nanoid()} className="tag">
                                    {tag}
                                  </span>
                                ))}
                                <StatusTag contentStatus={selfLearning.getStatus()} />
                              </span>
                            </div>
                            <div css={S.titleStyles}>{selfLearning.getTitle()}</div>
                            <div css={S.cardBottomStyles}>
                              <div css={S.cardBottomLeftStyles}>
                                <span css={S.cardBottomProblemCountStyles}>
                                  {selfLearning.getProblemCount().toLocaleString()}문제
                                </span>
                                <div className="vertical-divider" />
                                {selfLearning.getStatus() === CONTENT_STATUS.채점전 ? (
                                  <span css={S.cardBottomProblemCountStyles}>채점 전</span>
                                ) : (
                                  <span className="point">점수 {selfLearning.getScore()}점</span>
                                )}
                              </div>
                              {selfLearning.getStatus() !== CONTENT_STATUS.채점전 && (
                                <div css={S.cardBottomRightStyles}>
                                  <WrongCorrectCount
                                    wrongCount={selfLearning.getWrongCount()}
                                    correctCount={selfLearning.getCorrectCount()}
                                  />
                                </div>
                              )}
                            </div>
                          </div>
                          {selfLearning.자기주도_그룹_여부 && (
                            <div
                              css={css`
                                padding: 8px 20px;
                                border-top: 1px solid ${colors.gray.$200};
                              `}
                            >
                              <div
                                css={css`
                                  display: flex;
                                  gap: 4px;
                                  align-items: center;
                                  ${typo.caption01}
                                  color: ${colors.gray.$700};
                                  .text {
                                    white-space: nowrap;
                                  }
                                `}
                              >
                                <span className="text">진척도</span>
                                <ProgressBar
                                  percent={selfLearning.진척도}
                                  css={css`
                                    flex-direction: row-reverse;
                                    .progress-text {
                                      color: ${colors.gray.$700};
                                    }
                                  `}
                                />
                              </div>
                            </div>
                          )}
                        </StudentWorksheetCardAsChild>
                      </li>
                    ))}
                  </ul>
                </li>
              )
            })}
          </ul>
        )}
      </S.MobileContentsWrapper>
    </>
  )
})
