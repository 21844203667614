import * as S from '@effect/schema/Schema'
import { pipe } from 'effect'

import * as SchoolSystem from '~/@entities/SchoolSystem/schema.ts'

import { CurriculumDomain } from '..'

export const gradeTransform = pipe(
  S.number,
  S.transform(
    SchoolSystem.GradeElemMiddle,
    (n) => String(n) as SchoolSystem.GradeElementaryMiddle,
    (s) => parseInt(s),
  ),
)

export module ConceptDomain {
  export const Summary = S.struct({
    conceptId: S.number,
    totalUsed: S.number,
    correctTimes: S.number,
    wrongTimes: S.number,
    answerRate: S.number,
  })

  export const Concept = S.struct({
    id: S.number,
    curriculumId: S.number,
    school: SchoolSystem.SchoolType,
    grade: SchoolSystem.GradeElemMiddle,
    semester: SchoolSystem.Semester,
    name: S.string,
    url: S.string,
    // TODO: 백엔드한테 이게 뭔지 물어봐야 함
    outOfCurriculumType: CurriculumDomain.OutOfCurriculumType,
    hidden: S.boolean,
    conceptSummary: ConceptDomain.Summary,
  })

  // 여기서 number => string으로 변환 그러나 타입스크립트로 추론이 안되므로 실제는 Concept을 쓴다
  export const _Concept = S.struct({
    id: S.number,
    curriculumId: S.number,
    school: SchoolSystem.SchoolType,
    grade: gradeTransform,
    semester: SchoolSystem.Semester,
    name: S.string,
    url: S.string,
    // TODO: 백엔드한테 이게 뭔지 물어봐야 함
    outOfCurriculumType: CurriculumDomain.OutOfCurriculumType,
    hidden: S.boolean,
    conceptSummary: ConceptDomain.Summary,
  })
}
