import { ParseResult } from '@effect/schema'
import * as S from '@effect/schema/Schema'
import type { ValueOf } from '@mathflat/shared/@types/utilityTypes'

import type { SCHOOL_TYPE_KO_ABBR } from './constants'
import { REVISION, SCHOOL_TYPE, SUBJECT_HIGH } from './constants'

/***************** 학교 *****************/
/**
 * 학년 타입 (초, 중, 고)
 * <pre>
 * ELEMENTARY: 초등학교
 * MIDDLE: 중학교
 * HIGH: 고등학교
 * </pre>
 */
export const SchoolType = S.enums(SCHOOL_TYPE)
export type SchoolType = S.Schema.To<typeof SchoolType>
/******************************************/

/***************** 학년 *****************/
// 초등학교 학년
const GradeElementary = S.literal('1', '2', '3', '4', '5', '6')
// const NumGradeElementary = S.literal(1, 2, 3, 4, 5, 6)
// const NumGradeElementaryParser = S.parse(NumGradeElementary)
// export const GradeElementary = S.transformOrFail(
//   S.union(S.number, S.string),
//   NumGradeElementary,
//   (v) => NumGradeElementaryParser(isNumber(v) ? v : parseInt(v)),
//   (v) => ParseResult.succeed(v),
// )

// 중학교 학년
export const GradeMiddle = S.literal('1', '2', '3')
// export const NumGradeMiddle = S.literal(1, 2, 3)
// const NumGradeMiddleParser = S.parse(NumGradeElementary)
// export const GradeMiddle = S.transformOrFail(
//   S.union(S.number, S.string),
//   NumGradeElementary,
//   (v) => NumGradeMiddleParser(isNumber(v) ? v : parseInt(v)),
//   (v) => ParseResult.succeed(v),
// )

// 고등학교 학년
export const GradeHigh = S.enums(SUBJECT_HIGH)
export const SubjectHigh = GradeHigh

// 초중
export const GradeElemMiddle = S.union(GradeElementary, GradeMiddle)

// 전체 학년(초, 중, 고) <br/>
// 고등학교는 학년이 없고 과목만 있어서, 1~6학년의 값도 다 string으로
export const AllGradeSubject = S.union(GradeElemMiddle, GradeHigh)

export type GradeElementary = S.Schema.To<typeof GradeElementary>
export type GradeMiddle = S.Schema.To<typeof GradeMiddle>
export type GradeHigh = S.Schema.To<typeof GradeHigh>
// export type AllGradeSubject = S.Schema.To<typeof AllGradeSubject>
export type AllGradeSubject = S.Schema.To<typeof AllGradeSubject>
export type GradeElementaryMiddle = GradeElementary | GradeMiddle
/******************************************/

/***************** 학기 *****************/

/**
 * 고등학교는 학년-학기 구조가 아니라 과목구조인데요!
 * 그럼에도 학년과 학기를 정의해야하는 경우에 3학기로 정합니다.
 *
 * 예외 케이스)
 * 기하: 고등학교 3학년 3학기
 * (구)교육과정: 고등학교 4학년 1학기
 */
// 고등학교의 경우 학기가 없고 과목만 있어서, 고등학교 이하만 semester 값을 갖는다.
export const SemesterElementaryMiddle = S.literal(1, 2)
export const SemesterOldHigh = S.literal(1, 2, 3)
export const SemesterHigh = S.null
export const Semester = S.union(SemesterElementaryMiddle, SemesterOldHigh, SemesterHigh)

// export type Semester<T extends SchoolType = any> = T extends 'HIGH'
//   ? typeof SemesterHighSchema
//   : S.Schema.To<typeof SemesterElementaryMiddleSchema>

export type Semester = S.Schema.To<typeof Semester>
/******************************************/
export const HighSubject = S.attachPropertySignature(
  '_kind',
  '고등과목',
)(
  S.struct({
    grade: GradeHigh,
    schoolType: S.literal(SCHOOL_TYPE.고등학교),
  }),
)
export type HighSubjectFrom = S.Schema.From<typeof HighSubject>
export type HighSubject = S.Schema.To<typeof HighSubject>

export const HighGradeSemester = S.attachPropertySignature(
  '_kind',
  '고등학년',
)(
  S.struct({
    grade: GradeMiddle,
    semesters: SemesterOldHigh,
    schoolType: S.literal(SCHOOL_TYPE.고등학교),
  }),
)
export type HighGradeSemester = S.Schema.To<typeof MiddleGradeSemester>

export const MiddleGradeSemester = S.struct({
  grade: GradeMiddle,
  semesters: SemesterElementaryMiddle,
  schoolType: S.literal(SCHOOL_TYPE.중학교),
})
export type MiddleGradeSemester = S.Schema.To<typeof MiddleGradeSemester>

export const ElementaryGradeSemester = S.struct({
  grade: GradeElementary,
  semesters: SemesterElementaryMiddle,
  schoolType: S.literal(SCHOOL_TYPE.초등학교),
})

export const SchoolGradeSemester = S.union(
  HighSubject,
  HighGradeSemester,
  MiddleGradeSemester,
  ElementaryGradeSemester,
)
export type SchoolGradeSemester = S.Schema.To<typeof SchoolGradeSemester>

const SchoolGradeSemesterParser = S.parse(SchoolGradeSemester)

const SchoolGradeSemesterKeyAdapter = S.transformOrFail(
  SchoolGradeSemester,
  S.string,
  (p) =>
    ParseResult.succeed(
      `${p.schoolType}/${p.grade}${'semester' in p && p ? '/' + `${p.semester}` : ''}` as const,
    ),
  (v) => {
    const [schoolType, grade, semesters] = v.split('/')
    return SchoolGradeSemesterParser({
      schoolType,
      grade: schoolType === SCHOOL_TYPE.고등학교 ? grade : Number(grade),
      semesters: semesters ? Number(semesters) : null,
    })
  },
)
/***************** 개정 *****************/
export const Revision = S.enums(REVISION)
export type Revision = S.Schema.To<typeof Revision>

export type GradeElementaryMiddleAbbr =
  `${ValueOf<typeof SCHOOL_TYPE_KO_ABBR>}${GradeElementaryMiddle}`
